import React from 'react';
import {
    ConfirmTextContainer,
    ConfirmEmailText
} from './ExpiredLinkElements';

const ExpiredLink = () => {

    return (
        <ConfirmTextContainer>
            <ConfirmEmailText>
                This link is expired <a href='https://bakesly.com/signup'>sign up here</a> again to receive a new one!
            </ConfirmEmailText>
        </ConfirmTextContainer>
    )
}

export default ExpiredLink
