import React, {useState} from 'react';
import Topbar from '../components/Topbar';
import Navbar from '../components/Navbar';
import TOS from '../components/TOS';

const TOSPage = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
            <Topbar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <TOS />
        </>
        
    )
}

export default TOSPage
