import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

const AddCart = ({ product, service, closed }) => {
  const [values, setValues] = useState({
    quantity: "1",
  });

  const checkError = [];

  const { username } = useParams();

  const [notServ, setNotServ] = useState(false)

  const serviceData = JSON.parse(localStorage.getItem(username));
  const serviceArray = [];
  serviceArray.push(serviceData)

  const notify = () => toast.error("Can only order at one shop at a time, empty cart first!");
  const added = () => toast.success("Product added to cart!");
  const notAvail = () => toast.error("Order amount not available!");
  const closedShop = () => toast.error("This shop is currently closed!");
  const noService = () => toast.error("Conflicting with products in cart. Product is " + service.toLowerCase() + ".");

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  // if (product user id is not equal to cart product userid){}
  // product.userid === 
  const userProduct = Object.keys(sessionStorage);
  const userProductArray = [];
  const productArray = [];
  userProductArray.push(userProduct)

  userProductArray[0].map(product => { productArray.push(JSON.parse(sessionStorage.getItem(product))); })

  // console.log(productArray)

  useEffect(() => {
    if (closed === 0) {
      setNotServ(true)
    }

    productArray.map(check => {
      if (product.delivery !== check.delivery && product.pickup !== check.pickup) {
        setNotServ(true)
      }
    })
  }, [])

  const handleSubmit = e => {
    e.preventDefault();

    try {
      if (Number(product.instock) < Number(values.quantity) && closed === 1) {
        notAvail()
      } else if (notServ === false) {
        if (userProduct.length === 0) {
          if (product.delivery === true && product.pickup === true && serviceArray[0].delivery === true && serviceArray[0].pickup === true) {
            sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, name: product.username, quantity: values.quantity, delivery: true, pickup: true }));
          } else if (product.delivery === true && serviceArray[0].delivery === true) {
            sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, name: product.username, quantity: values.quantity, delivery: true, pickup: false }));
          } else if (product.pickup === true && serviceArray[0].pickup === true) {
            sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, name: product.username, quantity: values.quantity, delivery: false, pickup: true }));
          }
        } else {
          productArray.map(user => {
            if (user.user === product.userid) {
              if (product.delivery === true && product.pickup === true && serviceArray[0].delivery === true && serviceArray[0].pickup === true) {
                sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, name: product.username, quantity: values.quantity, delivery: true, pickup: true }));
              } else if (product.delivery === true && serviceArray[0].delivery === true) {
                sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, name: product.username, quantity: values.quantity, delivery: true, pickup: false }));
              } else if (product.pickup === true && serviceArray[0].pickup === true) {
                sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, name: product.username, quantity: values.quantity, delivery: false, pickup: true }));
              }
            }
          })
        }
      }

      productArray.map(product => {
        checkError.push(product.name)
      })

      const cleanError = [...new Set(checkError)];

      if (closed === 0) {
        closedShop()
      } else if (username !== cleanError[0] && cleanError[0] !== undefined) {
        notify();
      } else if (notServ === true && closed === 1) {
        noService()
      } else if (Number(product.instock) >= Number(values.quantity)) {
        added();
        window.location.reload(false)
      }

      // add to cart but if delivery cost cant add the delivery cost up and if two different delivery and pickup what then?

      // check if searchdata isnt empty if so first need adres
      // check if shop is in local storage and if delivery or pickup is true if not then do not add
      // check if there is another product in cart of another seller if so then pop up saying you can only buy from one seller at the time adding this will clear the cart

    } catch (err) {
      console.error(err.message);
    }
  };

  // if (shopError === true) {
  //   notify()  
  // }

  const handleReset = e => {
    e.preventDefault();

    try {
      sessionStorage.clear();

      if (product.delivery === true && product.pickup === true && serviceArray[0].delivery === true && serviceArray[0].pickup === true) {
        sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, quantity: values.quantity, delivery: true, pickup: true }));
      } else if (product.delivery === true && serviceArray[0].delivery === true) {
        sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, quantity: values.quantity, delivery: true, pickup: false }));
      } else if (product.pickup === true && serviceArray[0].pickup === true) {
        sessionStorage.setItem(product.producttitle, JSON.stringify({ product: product.productid, user: product.userid, quantity: values.quantity, delivery: false, pickup: true }));
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleSubmit, values, handleReset };
}

export default AddCart;