import React from 'react'
import {
    ForgotPassContainer,
    SignInLogo,
    UsernameInputWrapper,
    UsernameIconWrapper,
    Input,
    HomeBackButton,
    TOS,
    ForgotPassButton
} from '../ResetPass/ResetPasswordElements.js';
import { BiLockAlt, BiHome } from "react-icons/bi";
import { motion } from "framer-motion";
import useForm from './useForm';
import "../animationStyle.css";
import validate from './validate';
import { useParams } from "react-router-dom";

const ResetPass = () => {
    const { token } = useParams();

    const { handleChange, handleSubmit, values, errors, } = useForm(
        validate,
        token
    );

    return (
    <ForgotPassContainer>
            <HomeBackButton to="/">
                <motion.div className="BackHomeButton" whileTap={{ scale: 0.9 }}>
                    <BiHome size={30} color="#f0929f" />
                </motion.div>
            </HomeBackButton>
            <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20
            }}
            className="SignInAnimation"
            >
                    <SignInLogo src={require('../../images/logov2.png')} alt="Bakesly" />
                    <form onSubmit={handleSubmit} noValidate>
                    <UsernameInputWrapper>
                        <UsernameIconWrapper>
                            <BiLockAlt size={25} color="#f0929f" />
                        </UsernameIconWrapper>
                        <Input placeholder="Enter new password" name="password" type="password" value={values.password} onChange={handleChange} required />
                    </UsernameInputWrapper>
                    {errors.password && <p className="Errorstyle">{errors.password}</p>}
                    {/* <ForgotPassButton> */}
                        <motion.button  className="SignInAnimationButton" whileTap={{ scale: 0.9 }} type='submit'>Set new password</motion.button>
                    {/* </ForgotPassButton> */}
                    </form>
            </motion.div>
            <motion.div className="TOS" whileTap={{ scale: 0.9 }}>
                <TOS to="/termsofservice"><u>Terms of Service</u></TOS>
            </motion.div>
    </ForgotPassContainer>
    )
}

export default ResetPass
