import React, { useContext } from 'react';
import { useParams } from "react-router-dom";
import {
    ProductCardLink,
    ProductCardContents,
    CardBackground,
    PriceCard,
    PriceTitle,
    PriceTag,
    EditIcon,
    CloseProduct,
    Ready,
    // Service,
    CardLine
} from './ProductCardsElements';
import "../animationStyle.css";
import { motion } from 'framer-motion';
import { GrEdit, GrClose } from "react-icons/gr";
import Popup from 'reactjs-popup';
import EditPost from '../EditPost';
import ProductView from '../ProductView';
import { UsersContext } from '../../context/UsersContext';
import currencyArray from '../Currency/currency.json';

const ProductCards = ({ product, available, user, closed }) => {

    const { userAuth } = useContext(UsersContext);

    const { username } = useParams();

    const userView = userAuth.username;

    const curr = currencyArray[product.currency][0];

    const verifyUser = () => {
        if (!userView) {
            return <></>;
        } else if (userView === username) {
            return <Popup className="NewPostPopup" trigger={<EditIcon><GrEdit size={23} className='editPost' /></EditIcon>}>
                {close => (
                    <div>
                        <CloseProduct onClick={close} >
                            <GrClose size={25} color="black" />
                        </CloseProduct>
                        <EditPost close={close} product={product} user={user} />
                    </div>
                )}
            </Popup>
        } else {
            return <></>;
        }
    }

    return (
        <motion.div className="ProductCard">
            {verifyUser()}
            <Popup className="ProductPopup" trigger={
                <ProductCardContents>
                    <ProductCardLink>
                        <CardBackground src={product.images[0]} alt="Bakesly" />
                        <PriceCard>
                            <PriceTitle>{product.producttitle}</PriceTitle>
                            <Ready>Ready within: {product.productready}</Ready>
                            <CardLine></CardLine>
                            <PriceTag>{curr} {product.productprice}</PriceTag>
                            {/* <Service>Delivery and Pick up</Service> */}
                        </PriceCard>
                    </ProductCardLink>
                </ProductCardContents>
            }>
                {close => (
                    <div>
                        <CloseProduct onClick={close} >
                            <GrClose size={25} color="black" />
                        </CloseProduct>
                        <ProductView product={product} available={available} closed={closed} />
                    </div>
                )}
            </Popup>
        </motion.div>
    );
}

export default ProductCards;
