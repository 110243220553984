import React, { useState } from 'react';

import {
    ReviewContainer,
    ReviewInfoWrapper,
    UserName,
    ProductName,
    ReviewData,
    ReviewDate,
    ReviewStars,
    ReviewText,
    UserRating
} from './ReviewElements';
// import Rating from '@material-ui/lab/Rating';
import Rating from '@mui/material/Rating';

const Review = ({ review }) => {

    const [values, setValues] = useState({
        rating: review.rating
    });

    var timesplit = review.time_review.split(" ")[1];
    var timedetail = timesplit.split(":")[0] + ":" + timesplit.split(":")[1];
    
    return (
        <ReviewContainer>
            <ReviewInfoWrapper>
                {/* <UserName>Braoo Draoo</UserName>
                <ProductName>Nameofcakeofiets</ProductName> */}
                <ReviewDate>{timedetail} {review.time_review.split(" ")[0]}</ReviewDate>
            </ReviewInfoWrapper>
            <ReviewData>
                <ReviewStars><Rating className="UserRating" name="read-only" value={Number(values.rating)} size="small" precision={0.5} readOnly /><UserRating><u>{review.rating}/5</u></UserRating></ReviewStars>
            </ReviewData>
            <ReviewText><p>{review.review}</p></ReviewText>
        </ReviewContainer>
    )
}

export default Review
