import styled from 'styled-components';

export const Wait = styled.div`
    font-size: 22px;
    width: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 100px;

    @media screen and (max-width: 480px) {
        width: 90%;
    }
`;

export const OrderListWrapper = styled.div`
    margin-top: 25px;    
    padding: 20px;
    border-radius: 10px;
    height: fit-content;

    @media screen and (max-width: 768px) {
        // width: 600px;
        margin: auto;
    }

    @media screen and (max-width: 480px) {
        width: fit-content;
    }

    @media screen and (max-width: 320px) {
        padding: 10px;
    }
`;

export const CheckOutListTitle = styled.div`
    padding-bottom: 10px;
    font-size: 50px;
    background: linear-gradient(314deg, rgba(182,216,243,1) 4%, rgba(182,216,243,1) 10%, rgba(245,206,224,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
    width: fit-content;

    // @media screen and (max-width: 1024px) {
    //     width: 556px;
    // }

    // @media screen and (max-width: 768px) {
    //     margin: auto;
    //     width: 576px;

    // }

    // @media screen and (max-width: 480px) {
    //     width: 275px;
    //     margin-top: 20px;
    // }
`;

export const CheckOutLine = styled.div`
    height: 2px;
    width: 100%;
    margin: auto;
    background: linear-gradient(314deg,rgba(182,216,243,1) 4%,rgba(182,216,243,1) 10%,rgba(245,206,224,1) 100%);
`;

export const TotalCostWrapper = styled.div`
    margin-top: 15PX;
    width: 100%;
    font-size: 16px;
    color: #757575;
`;
