import styled from 'styled-components'

export const OrderContainer = styled.div`

`;

export const OrderWrapper = styled.div`
    height: 400px;
    width: 90%;
    background: whitesmoke;
    border-radius: 10px;
    margin: auto;
    padding: 10px;
    margin-top: 20px;
`;

export const TableButton = styled.div`
    width: 80%;
    margin: auto;
    margin-top: 10px;
`;

export const SetupStripeContainer = styled.div`
    height: 500px;
    position: relative;
`;

export const StripeContent = styled.div`
    text-align: center;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const StripeTitle = styled.h4`
    margin-bottom: 15px;
`;

export const CancelPopup = styled.div`

`;

export const OrderPopup = styled.div`

`;

export const Button = styled.div`
    text-align: center;
`;