import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
    ConfirmTextContainer,
    ConfirmEmailText
} from './ConfirmEmailElements';
import UserData from '../../API/UserFinder';

const ConfirmEmail = () => {

    const { token } = useParams();

    useEffect(() => {
        const fetchConfirmData = async () => {
            try {
                const getConfirm = await UserData.get('/confirmation/' + token).then(data => {
                    // console.log(data)
                    if (data.data === "true") {
                        window.location.replace("/building");
                    } else if (data.data === "expired") {
                        window.location.replace("/expired");
                    }
                });

            } catch (err) {
                window.location.replace("/");
                console.error(err.message);
            }
        };

        fetchConfirmData();
    }, [token]);

    return (
        <>
        </>
    )
}

export default ConfirmEmail
