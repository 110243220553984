import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import Home from './pages';
import AboutPage from './pages/about';
import SignInPage from './pages/signin';
import ExplorePage from './pages/explore';
import SignUpPage from './pages/signup';
import ConfirmEmailPage from './pages/confirmemail';
import ExpiredLinkPage from './pages/expired';
import ForgotPassPage from './pages/forgotpass';
import ReviewPage from './pages/review';
import ResetPassPage from './pages/resetpw';
import ConfirmDeletePage from './pages/deleted';
import OrderPage from './pages/order';
import UserPage from './pages/user';
import TOSPage from './pages/tos';
import PPPage from './pages/pp';
import LaunchPage from './pages/launch';
import UserSettingsPage from './pages/usersettings';
import MyOrdersPage from './pages/myorders';
import CheckOutPage from './pages/checkout';
import CompletePage from './pages/complete';
import CheckEmailPage from './pages/checkmail';
import BuildingPage from './pages/building';
import TwoFALogin from './pages/twofa';
import CompleteSignUp from './pages/completesignup';
import UsersContext, { UsersContextProvider } from './context/UsersContext';
import PresistLogin from './components/PresistLogin/index'
import useAuth from './Hooks/useAuth';
import useRefreshToken from './Hooks/useRefreshToken';
// import Testmap from './pages/testmap'
// import axiosAuth from './API/UserFinder';

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const setAuth = boolean => {
    setIsAuthenticated(boolean);
  };

  const saveSearch = () => {
    const search = localStorage.getItem('searchData');
    setSearchData(search);
  }

  useEffect(() => {
    saveSearch();
  }, []);

  return (
    <UsersContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/" replace />} />
          
          {/* <Route path="/map" element={<Testmap />} /> */}
          
          <Route element={<PresistLogin setAuth={setAuth} />}>
            <Route
              path="/"
              element={
                !searchData ? (
                  <Home saveSearch={saveSearch} />
                ) : (
                  <Navigate to="/explore" replace={true}/>
                )

              }
            />
            {/* <Route
              path="/"
              element={
                  <LaunchPage />
              }
            /> */}
            <Route path="about" element={<AboutPage />} />
            <Route path="termsofservice" element={<TOSPage />} />
            <Route path="privacypolicy" element={<PPPage />} />
            <Route
              path="explore"
              element={
                !searchData ? (
                  <Navigate to="/" />
                ) : (
                  <ExplorePage setAuth={setAuth} />
                )
              }
            />
            <Route path="checkmail" element={<CheckEmailPage />} />
            <Route path="expired" element={<ExpiredLinkPage />} />
            <Route path="building" element={<BuildingPage />} />
            <Route
              path="signin"
              element={
                !isAuthenticated ? (
                  <SignInPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/myorders" />
                )
              }
            />
            <Route
              path="signup"
              element={
                !isAuthenticated ? (
                  <SignUpPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/myorders" />
                )
              }
            />
            <Route path="confirmation/:token" element={<ConfirmEmailPage />} />
            <Route path="forgotpassword" element={<ForgotPassPage />} />
            <Route path="resetpw/:token" element={ <ResetPassPage /> } />
            <Route path="deleted/:token" element={ <ConfirmDeletePage /> } />

            <Route
              path="review/:token"
              element={
                <ReviewPage setAuth={setAuth} />
              }
            />
            <Route
              path="order"
              element={
                <OrderPage setAuth={setAuth} />
              }
            />
            <Route
              path="checkout"
              element={
                !searchData ? (
                  <Navigate to="/" />
                ) : (
                  <CheckOutPage setAuth={setAuth} />
                )
              }
            />
            <Route
              path="complete"
              element={
                !searchData ? (
                  <Navigate to="/" />
                ) : (
                  <CompletePage setAuth={setAuth} />
                )
              }
            />
            <Route
              path="completesignup"
              element={
                !isAuthenticated ? (
                  <CompleteSignUp setAuth={setAuth} />
                ) : (
                  <Navigate to="/myorders" />
                )
              }
            />
            <Route
              path="settings"
              element={
                isAuthenticated ? (
                  <UserSettingsPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/signin" />
                )
              }
            />
            <Route
              path="myorders"
              element={
                isAuthenticated ? (
                  <MyOrdersPage setAuth={setAuth} />
                ) : (
                  <Navigate to="/signin" />
                )
              }
            />
            <Route
              path=":username"
              element={
                <UserPage setAuth={setAuth} />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </UsersContextProvider>
  );
}

export default App;