import React, { useContext, useEffect, useState, useRef } from 'react'
import {
    OrderContainer,
    OrderWrapper,
    TableButton,
    SetupStripeContainer,
    StripeTitle,
    StripeContent,
    CancelPopup,
    Button,
    OrderPopup
} from './MyOrdersElements';
// import { DataGrid } from '@material-ui/data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { motion } from "framer-motion";
import "../animationStyle.css";
import { UsersContext } from '../../context/UsersContext';
import useAuth from '../../Hooks/useAuth';
import spinner from '../../images/Spinner-1s-200px.gif';
import Popup from 'reactjs-popup';

const MyOrders = () => {

    const [paymentSetup, setPayementSetup] = useState(true)
    const [showLoader, setShowLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [order, setOrder] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const tableInput = [];
    let table = {}
    let price;
    let timesplit;
    let timedetail;
    let pricedetail;

    const axiosAuth = useAuth();

    const { userAuth } = useContext(UsersContext);

    const userid = userAuth.user;

    /// state is not going to work after refresh 

    // create a use user data hook check in hook if token then

    useEffect(() => {

        const fetchOrders = async () => {
            try {
                await axiosAuth.get('/myorders', {
                    headers: {
                        user: userAuth.user
                    }
                }).then(data => {
                    setTableData(data.data);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchOrders();

        const fetchPaymentCheck = async () => {
            try {
                await axiosAuth.get('/check', {
                    headers: {
                        user: userAuth.user
                    }
                }).then(data => {
                    setPayementSetup(data.data)
                });
            } catch (err) {
                setPayementSetup(false)
                console.error(err.message);
            }
        }

        fetchPaymentCheck();

        const fetchOrderDetails = async () => {
            try {
                await axiosAuth.get('/orderDetails', {
                    headers: {
                        orderid: JSON.stringify(orderDetails[0])
                    }
                }).then(data => {
                    setOrder(data.data[0]);
                });
            } catch (err) {
                console.error(err.message);
            }
        };

        if (orderDetails[0] !== undefined) {
            fetchOrderDetails();
        }

    }, [orderDetails]);

    const columns = [
        { field: 'fullName', headerName: 'Full name', width: 180 },
        { field: 'address', headerName: 'Address', width: 160 },
        { field: 'zipcode', headerName: 'Zipcode', width: 140 },
        { field: 'Note', headerName: 'Note', width: 220 },
        { field: 'DeliveryPickup', headerName: 'Service', width: 130 },
        { field: 'product', headerName: 'Product(s)', width: 200 },
        { field: 'status', headerName: 'Status', width: 180 },
        { field: 'time', headerName: 'Time', width: 110 },
        { field: 'date', headerName: 'Date', width: 110 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone', headerName: 'Phone', width: 180 },
        { field: 'price', headerName: 'Payed', width: 130 }
    ];

    if (tableData.length !== 0) {
        // tableDataArray.push(tableData)
        // console.log(tableDataArray)
        tableData.map(row => {
            if (row.currency !== 'JPY') {
                price = (Number(row.totalcost) - Number(row.servicecost)) / 100;
            } else {
                price = (Number(row.totalcost) - Number(row.servicecost));
            }

            if (row.length !== 0) {

                var time = row.order_time.split(" ")[1];

                table = {
                    id: row.orderid,
                    fullName: row.firstname + " " + row.lastname,
                    email: row.emailaddress,
                    time: time.split(":")[0] + ":" + time.split(":")[1],
                    product: row.productnames,
                    status: row.status,
                    price: price,
                    DeliveryPickup: row.service,
                    Note: row.ordernote,
                    date: row.date.split(" ")[0],
                    phone: row.phonenumber,
                    address: row.address,
                    zipcode: row.zipcode
                };

                tableInput.push(table)
            }
        })
    }

    const ref = useRef();
    const CloseSubmit = () => ref.current.close();

    const CancelSubmit = (e) => {

    }

    const dataRows = tableInput;

    // const dataRows = [{
    //     id: "1",
    //     fullName: "",
    //     email: "",
    //     time: "",
    //     product: "",
    //     price: "",
    //     DeliveryPickup: "",
    //     Note: "",
    //     date: "",
    //     phone: "",
    //     address: ""
    // }];

    const stripeOnclick = async (e) => {
        e.preventDefault();

        setShowLoader(true)

        try {
            const getStripe = await axiosAuth.get('/stripe', {
                headers: {
                    user: userAuth.user
                }
            })

            window.location.href = getStripe.data;

        } catch (err) {
            console.error(err.message);
        }

    }

    const Complete = async () => {
        try {
            if (order.orderid !== undefined) {
                await axiosAuth.get('/completeOrder', {
                    headers: {
                        orderid: order.orderid
                    }
                }).then(data => {
                    if (data.data === "Complete") {
                        window.location.reload();
                    } else {
                        console.log("Already completed or refunded")
                    }
                })
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    const CancelOrder = async () => {
        try {
            if (order.orderid !== undefined) {
                setShowLoader(true)
                await axiosAuth.get('/refundorder', {
                    headers: {
                        orderid: order.orderid
                    }
                }).then(data => {
                    if (data.data === "Refunded") {
                        window.location.reload();
                    } else if (data.data === "Already") {
                        console.log("Already refunded")
                        setShowLoader(false)
                    }
                })
            } else {
                setShowLoader(false)
            }

        } catch (err) {
            console.error(err.message);
        }
    }

    const ProductTable = () => {
        if (order.productnames !== undefined) {
            return order.productnames.map(product => 
            <tr key={product.split(" ")[2]}>
                <td>{product.split(" ")[2]}</td>
                <td>{product.split(" ")[0]}</td>
            </tr>
            )
        }
    }


    if (order.order_time !== undefined) {
        timesplit = order.order_time.split(" ")[1];
        timedetail = timesplit.split(":")[0] + ":" + timesplit.split(":")[1];

        if (order.currency !== 'JPY') {
            pricedetail = (Number(order.totalcost) - Number(order.servicecost)) / 100;
        } else {
            pricedetail = (Number(order.totalcost) - Number(order.servicecost));
        }
    }

    return (
        !paymentSetup ? (
            <SetupStripeContainer>
                <StripeContent>
                    <StripeTitle>Almost done, payment information is needed!</StripeTitle>
                    {!showLoader ? (
                        <motion.button className="setupstripebutton" whileTap={{ scale: 0.9 }} onClick={stripeOnclick}>Click here to set up Stripe!</motion.button>
                    ) : (
                        <img src={spinner} alt="Loading" width="48" height="48" />
                    )
                    }
                </StripeContent>
            </SetupStripeContainer>
        ) : (
            <OrderContainer>
                <OrderWrapper>
                    <DataGrid className="GridStyle"
                        rows={dataRows}
                        checkboxSelection={false}
                        onRowSelectionModelChange={itm => setOrderDetails(itm)}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    />
                </OrderWrapper>
                <TableButton>
                    <Popup className="Orderbox" ref={ref} trigger={<motion.button className="OrderComplete" whileTap={{ scale: 0.9 }}>Show order details</motion.button>}>
                        <OrderPopup>
                            <table className='orderview'>
                                <tbody>
                                    <tr>
                                        <td>Customer:</td>
                                        <td>{order.firstname + " " + order.lastname}</td>
                                    </tr>
                                    <tr>
                                        <td>Address:</td>
                                        <td>{order.address}</td>
                                    </tr>
                                    <tr>
                                        <td>Zipcode:</td>
                                        <td>{order.zipcode}</td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td>{order.emailaddress}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone:</td>
                                        <td>{order.phonenumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Time and date:</td>
                                        <td>{timedetail + " " + order.date}</td>
                                    </tr>
                                    <tr>
                                        <td>Note:</td>
                                        <td>{order.ordernote}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{order.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Service:</td>
                                        <td>{order.service}</td>
                                    </tr>
                                    <tr>
                                        <td>Payed:</td>
                                        <td>{pricedetail}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Products:</b></td>
                                        <td><b>Amount:</b></td>
                                    </tr>
                                    {ProductTable()}
                                </tbody>
                            </table>
                        </OrderPopup>
                        <Button>
                            <motion.button className="OrderButton" onClick={CloseSubmit} whileTap={{ scale: 0.9 }}>Close</motion.button>
                        </Button>
                    </Popup>
                    <motion.button className="OrderComplete" whileTap={{ scale: 0.9 }} onClick={Complete}>Complete order!</motion.button>
                    <Popup className="Cancelbox" ref={ref} trigger={<motion.button className="OrderCancel" whileTap={{ scale: 0.9 }}>Cancel Order(s)</motion.button>}>
                        <CancelPopup>Are you sure that you want to cancel and refund this order? Customers are partially refunded. <a href="https://bakesly.com/termsofservice" target="_blank">Bakesly service fees</a> are deducted from refunds and 20% is excluded to protect you from <a href="https://bakesly.com/termsofservice" target="_blank">administrative costs</a>. Full refunds can be done via the Stripe dashboard. Beware of additional costs for your shop due to <a href="https://support.stripe.com/questions/understanding-fees-for-refunded-payments" target="_blank">Stripe fees</a>. It's your duty to inform the client before refunding as stated in the <a href="https://bakesly.com/termsofservice" target="_blank">Terms of Service</a>.</CancelPopup>
                        <Button>
                            {!showLoader ? (
                                <>
                                    <motion.button className="NoButton" onClick={CloseSubmit} whileTap={{ scale: 0.9 }}>No</motion.button>
                                    <motion.button type="submit" className="YesButton" onClick={CancelOrder} whileTap={{ scale: 0.9 }}>Yes</motion.button>
                                </>
                            ) : (
                                <img src={spinner} alt="Loading" width="48" height="48" />
                            )
                            }
                        </Button>
                    </Popup>
                </TableButton>
            </OrderContainer>
        )
    )
}

export default MyOrders
