export default function validate(values, selectedImage) {
    let errors = {};

    if (!selectedImage || selectedImage.file === undefined) {
      errors.profileimage = 'Profile image required';
    } 
    else if (!selectedImage.file.name.match(/.(jpg|png|jpeg)$/i)) {
      errors.profileimage = 'Image has to be jpg or png';
    }
    else if (!selectedImage.file.type.match(/.(jpg|png|jpeg)$/i)) {
      errors.profileimage = 'Image has to be jpg or png';
    }

    if (!values.firstname) {
      errors.firstname = 'First name is required';
    }
    else if (!/^[A-Za-z]+$/.test(values.firstname)) {
      errors.firstname = 'Enter a valid first name';
    }
    else if (values.firstname.length < 3) {
      errors.firstname = 'First name is to short';
    }
    else if (values.firstname.length > 30) {
      errors.firstname = 'First name is to long';
    }

    if (!values.lastname) {
      errors.lastname = 'Last name is required';
    }
    else if (!/^[A-Za-z]+$/.test(values.lastname)) {
      errors.lastname = 'Enter a valid last name';
    }
    else if (values.lastname.length < 3) {
      errors.lastname = 'Last name is to short';
    }
    else if (values.lastname.length > 30) {
      errors.lastname = 'Last name is to long';
    }

    if (!values.businessname) {
      errors.businessname = 'Business name is required';
    }
    else if (!/^[a-zA-Z0-9'&* ]+( [a-zA-Z0-9'&* ]+)*$/.test(values.businessname)) {
      errors.businessname = 'Enter a valid business name';
    }
    else if (values.businessname.length < 3) {
      errors.businessname = 'Business name is to short';
    }
    else if (values.businessname.length > 20) {
      errors.businessname = 'Business name is to long';
    }

    if (!values.emailaddress.trim()) {
      errors.emailaddress = 'Email address is required';
    } 
    else if (values.emailaddress === "User already exist") {
      errors.emailaddress = 'User already exist';
    }
    else if (!/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(values.emailaddress)) {
      errors.emailaddress = 'Email address is not registered';
    }
    else if (values.emailaddress.length < 4) {
      errors.emailaddress = 'Email address is to short';
    }
    else if (values.emailaddress.length > 50) {
      errors.emailaddress = 'Email address is to long';
    }


    if (!values.phonenumber.trim()) {
      errors.phonenumber = 'Phone number is required';
    } 
    else if (!/^[0-9]+$/.test(values.phonenumber)) {
      errors.phonenumber = 'Phone number is invalid';
    }
    else if (values.phonenumber.length < 4) {
      errors.phonenumber = 'Phone number is to short';
    }
    else if (values.phonenumber.length > 20) {
      errors.phonenumber = 'Phone number is to long';
    }

    if (!values.bio) {
      errors.bio = 'Bio is required';
    } 
    else if (!/^[a-zA-Z0-9,.*&' ]+( [a-zA-Z0-9,.*&' ]+)*$/.test(values.bio)) {
      errors.bio = 'Bio is invalid';
    }
    else if (values.bio.length < 10) {
      errors.bio = 'Bio is to short';
    }
    else if (values.bio.length > 75) {
      errors.bio = 'Bio is to long';
    }

    // /[!@#$%^&*()\=\[\]{};'"\\|,<>\/?]+/,

    // /[!@#$%^&*()+=\[\]{};':"|,<>?]+/

    // /[!@#$%^&*()_+ \- = \[ \] {};':" \\ |,.<> \/ ?]+/

    // fix this under this
    if (!values.location) {
      errors.location = 'Location is required';
    }
    else if (values.location.length < 5) {
      errors.location = 'Location is to short';
    }
    // else if (!/^[A-Za-z]+$/.test(values.location)) {
    //   errors.location = 'Location is invalid';
    // }


    if (!values.streetname) {
      errors.streetname = 'Street name is required';
    }
    else if (!/^[A-Za-z ]+( [A-Za-z ]+)*$/.test(values.streetname)) {
      errors.streetname = 'Enter a valid street name';
    }
    else if (values.streetname.length < 3) {
      errors.streetname = 'Street name is to short';
    }
    else if (values.streetname.length > 90) {
      errors.streetname = 'Street name is to long';
    }

    if (!values.streetnumber.trim()) {
      errors.streetnumber = 'Street number is required';
    } 
    else if (!/^[0-9a-zA-Z]+$/.test(values.streetnumber)) {
      errors.streetnumber = 'Street number is invalid';
    }
    else if (values.streetnumber.length > 7) {
      errors.streetnumber = 'Street number is to long';
    }

    if (!values.place) {
      errors.location = 'Location is required';
    }
    else if (values.place.length < 5) {
      errors.location = 'Location is to short';
    }

    if (!values.sellingdistance) {
      errors.sellingdistance = 'Distance required';
    }
    else if (!/^[0-9]+$/.test(values.sellingdistance)) {
      errors.sellingdistance = 'Distance is invalid';
    }
    else if (values.sellingdistance.length < 3) {
      errors.sellingdistance = 'Distance is to short';
    }
    else if (values.sellingdistance.length > 5) {
      errors.sellingdistance = 'Distance is to long';
    }

    if (!values.currency) {
      errors.currency = 'Currency is required';
    }
    else if (!/^[A-Za-z]+$/.test(values.currency)) {
      errors.currency = 'Currency is invalid';
    }

    if (!values.mindeliveryprice.trim()) {
      errors.mindeliveryprice = 'Min. amount required';
    } 
    else if (!/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(values.mindeliveryprice)) {
      errors.mindeliveryprice = 'Min. amount is invalid';
    }
    else if (values.mindeliveryprice.length > 6) {
      errors.mindeliveryprice = 'Min. amount is to high';
    }

    // check password validate
    if (!values.password) {
      errors.password = 'Password is required';
    } 
    else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(values.password)) {
      errors.password = 'Password must contain at least 1 special character, 1 numeric character and 1 uppercase character';
    }
    else if (!/[A-Z]/.test(values.password)) {
      errors.password = 'Password must contain at least 1 special character, 1 numeric character and 1 uppercase character';
    }
    else if (values.password.length < 8) {
      errors.password = 'Password needs to be 8 characters or more';
    }

    if (!values.password2) {
      errors.password2 = 'Password is required';
    } 
    else if (values.password2 !== values.password) {
      errors.password2 = 'Passwords do not match';
    }

    // if (!values.paypalemail.trim()) {
    //   errors.paypalemail = 'Paypal email required';
    // } 
    // else if (!/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(values.paypalemail)) {
    //   errors.paypalemail = 'Paypal email address is invalid';
    // }
    // else if (values.paypalemail.length < 4) {
    //   errors.paypalemail = 'Paypal email to short';
    // }
    // else if (values.paypalemail.length > 50) {
    //   errors.paypalemail = 'Paypal email  is to long';
    // }

    if (!values.mon_open || !values.mon_closed  || !values.tue_open || !values.tue_closed || !values.wed_open || !values.wed_closed || !values.thu_open || !values.thu_closed || !values.fri_open || !values.fri_closed || !values.sat_open || !values.sat_closed || !values.sun_open || !values.sun_closed) {
      errors.mon_open = 'Open times are required';
    } 
    else if (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.mon_open) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.mon_closed) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.tue_open) | !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.tue_closed) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.wed_open) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.wed_closed) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.tue_open) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.tue_closed) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.fri_open) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.fri_closed) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.sat_open) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.sat_closed) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.sun_open) || !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(values.sun_closed)) {
      errors.mon_open = 'Open times should be numbers';
    }

    if (values.tos === false) {
      errors.tos = 'Checkbox is required';
    }

    if (!values.username) {
      errors.username = 'Username is required';
    }
    else if (values.username === "Username already exist") {
      errors.username = 'Username already exist';
    }
    else if (!/^[0-9a-zA-Z]+$/.test(values.username)) {
      errors.username = 'Enter a valid username';
    }
    else if (values.username.length < 3) {
      errors.username = 'Username is to short';
    }
    else if (values.username.length > 11) {
      errors.username = 'Username is to long';
    }

    if (!values.mintime) {
      errors.mintime = 'Min. time is required';
    }
    else if (!/^[0-9a-zA-Z ]+$/.test(values.mintime)) {
      errors.mintime = 'Enter a valid min. time';
    }
    else if (values.mintime.length < 4) {
      errors.mintime = 'Min. time is required';
    }
    else if (values.mintime.length > 9) {
      errors.mintime = 'Min. time is required';
    }

    if (!values.maxtime) {
      errors.maxtime = 'Max. time is required';
    }
    else if (!/^[0-9a-zA-Z ]+$/.test(values.maxtime)) {
      errors.maxtime = 'Enter a valid max. time';
    }
    else if (values.maxtime.length < 4) {
      errors.maxtime = 'Max. time is required';
    }
    else if (values.maxtime.length > 9) {
      errors.maxtime = 'Max. time is required';
    }

    if (values.delivery === false && values.pickup === false) {
      errors.delivery = 'Service is required';
    }

    return errors;
  }