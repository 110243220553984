import React, {useState} from 'react';
import Topbar from '../components/Topbar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import FuncCookies from '../components/FuncCookies';

const Home = ({ saveSearch }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Topbar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      {/* <FuncCookies /> */}
      <HeroSection saveSearch={saveSearch} />
    </>
  );
}

export default Home
