import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { rotate } from "../.././util/Rotate";

const mapboxtoken = rotate;

export const geocoder = new MapboxGeocoder({
  accessToken: mapboxtoken,
  mapboxgl: mapboxgl,
  limit: 4,
  minLength: 4,
  placeholder: "Enter your address"
});

export var geoLocation;
export var geoPlace;

export function setSearch () {
  geocoder.addTo("#geocoder");
}

geocoder.on('results', (results) => geoData(results));

export function geoData (results) {
  if (results.features[0] === undefined) {
    geoLocation = "";
    geoPlace = "";
  } else if (results.features[0].geometry !== undefined) {
    geoLocation = results.features[0].geometry.coordinates[0] + ', ' + results.features[0].geometry.coordinates[1];
    geoPlace = results.features[0].place_name;
  } 

  return [geoLocation, geoPlace];
}

geocoder.on('clear', () => {geoLocation = ""; geoPlace = "";});