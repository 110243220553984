import React, {useState} from 'react'
import MobileMainNav from '../components/MobileMainNav';
import MainNavigation from '../components/MainNavigation';
import MyOrders from '../components/MyOrders';
import '../components/animationStyle.css';

const MyOrdersPage = ({ setAuth }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
            {/* <MobileMainNav isOpen={isOpen} toggle={toggle} setAuth={setAuth} /> */}
            <div className="hideOnUser">
                <MainNavigation toggle={toggle} setAuth={setAuth} />
            </div>
            <MyOrders />
        </>
    )
}

export default MyOrdersPage
