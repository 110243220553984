export default function validate(values) {
    let errors = {};

    if (!values.review) {
      errors.review = 'Review is required';
    }
    else if (!/^[a-zA-Z0-9,.*&']+( [a-zA-Z0-9,.*&']+)*$/.test(values.review)) {
      errors.review = 'Review is invalid';
    }
    else if (values.review.length < 10) {
      errors.review = 'Review is to short';
    }
    else if (values.review.length > 110) {
      errors.review = 'Review is to long';
    }
    else if (values.review === "Review already exist") {
      errors.review = 'Review already exist';
    }
    else if (values.review === "Review expired") {
      errors.review = 'Review expired';
    }

    if (values.rating <= 0 ) {
      errors.review = 'Rating is required';
    }
    else if (values.rating > 5) {
      errors.review = 'Rating is wrong';
    }

    return errors;
  }