export default function validate(values, imageValidate) {
    let errors = {};
    
    if (values.title === "Try again") {
      errors.title = 'You have used that title already';
    }
    else if (!values.title) {
      errors.title = 'Title is required';
    }
    else if (!/^[a-zA-Z0-9,.*&' ]+( [a-zA-Z0-9,.*&' ]+)*$/.test(values.title)) {
      errors.title = 'Enter a valid title';
    }
    else if (values.title.length < 2) {
      errors.title = 'Title is to short';
    }
    else if (values.title.length > 60) {
      errors.title = 'Title is to long';
    }

    if (!values.price) {
      errors.price = 'Price is required';
    }
    else if (!/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(values.price)) {
      errors.price = 'Enter a valid price';
    }
    else if (values.price < 400 && values.jpy === 'JPY') {
      errors.price = 'Price is to low';
    }
    else if (values.price < 2) {
      errors.price = 'Price is to low';
    }
    else if (values.price.length > 9) {
      errors.price = 'Price is to high';
    }

    if (!values.deliveryprice) {
      values.deliveryprice = 0;
    }
    else if (!/^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(values.deliveryprice)) {
      errors.deliveryprice = 'Enter a valid delivery price';
    }
    else if (values.deliveryprice.length > 6) {
      errors.deliveryprice = 'Delivery price is to high';
    }

    if (!values.description) {
      errors.description = 'Description is required';
    }
    else if (!/^[a-zA-Z0-9,.*&' ]+( [a-zA-Z0-9,.*&' ]+)*$/.test(values.description)) {
      errors.description = 'Enter a valid description';
    }
    else if (values.description.length < 50) {
      errors.description = 'Description is to short';
    }
    else if (values.description.length > 255) {
      errors.description = 'Description is to long';
    }

    if (!values.ingredients) {
      errors.ingredients = 'Ingredients is required';
    }
    else if (!/^[a-zA-Z0-9,.*&' ]+( [a-zA-Z0-9,.*&' ]+)*$/.test(values.ingredients)) {
      errors.ingredients = 'Enter valid ingredients';
    }
    else if (values.ingredients.length < 50) {
      errors.ingredients = 'Ingredients is to short';
    }
    else if (values.ingredients.length > 170) {
      errors.ingredients = 'Ingredients is to long';
    }

    if (!values.time) {
      errors.time = 'Time is required';
    }
    else if (!/^[a-zA-Z0-9 ]+$/.test(values.time)) {
      errors.time = 'Select a valid time';
    }
    else if (!values.time.length > 8) {
      errors.time = 'Select a valid time';
    }

    if (values.tags[0] === undefined) {
      errors.tags = 'Min. 1 tag is required';
    }
    else if (values.tags.length > 5) {
      errors.tags = 'Max. 5 tags are valid';
    }
    else if (!/^[0-9]+$/.test(values.tags[0].tagid)) {
      errors.tags = 'Tags are invalid';
    }

    if (!values.contact) {
      errors.contact = 'Time is required';
    }
    else if (!/^[a-zA-Z0-9 ]+$/.test(values.contact)) {
      errors.contact = 'Select a valid time';
    }
    else if (!values.contact.length > 7) {
      errors.contact = 'Select a valid time';
    }

    if (!values.quantity) {
      errors.quantity = 'Quantity is required';
    } 
    else if (!/^[0-9]+$/.test(values.quantity)) {
      errors.quantity = 'Quantity is invalid';
    }
    else if (values.quantity.length > 5) {
      errors.quantity = 'Quantity is to high';
    }

    if (values.delivery === false && values.pickup === false) {
      errors.delivery = 'Service is required';
    }

    if (!imageValidate[0] || imageValidate[0] === '') {
      errors.images = 'Image required';
    }
    else if (imageValidate.length > 0 && imageValidate[0].type !== undefined) {
      if(!imageValidate[0].type.match(/.(jpg|png|jpeg)$/i)) {
        errors.images = 'Image has to be jpg or png';
      }
    }
    else if (imageValidate.length > 1 && imageValidate[1].type !== undefined) {
      if(!imageValidate[1].type.match(/.(jpg|png|jpeg)$/i)) {
        errors.images = 'Image has to be jpg or png';
      }
    }
    else if (imageValidate.length > 2 && imageValidate[2].type !== undefined) {
      if(!imageValidate[2].type.match(/.(jpg|png|jpeg)$/i)) {
        errors.images = 'Image has to be jpg or png';
      }
    }
    else if (imageValidate.length > 3 && imageValidate[3].type !== undefined) {
      if(!imageValidate[3].type.match(/.(jpg|png|jpeg)$/i)) {
        errors.images = 'Image has to be jpg or png';
      }
    }
    else if (imageValidate.length > 4 && imageValidate[4].type !== undefined) {
      if(!imageValidate[4].type.match(/.(jpg|png|jpeg)$/i)) {
        errors.images = 'Image has to be jpg or png';
      }
    }
    else if (imageValidate.length > 5) {
      errors.images = 'Max. 5 images';
    }

    return errors;
  }