import React, { useEffect, useState } from 'react';
import {
    OrderTable,
    ProductImageWrapper,
    ProductImage,
    ProductTitle,
    ProductQuantity,
    ProductCardLink
} from './OrderProductsElements';

const OrderProducts = ({ product, info }) => {

    const dataArray = product.split(":");

    return (
        <ProductCardLink to={"/" + info.shop}>
            <OrderTable>
                <tbody>
                    <tr>
                        {/* <td>
                            <ProductImageWrapper>
                                <ProductImage src={product.images[0]} alt="Product picture" />
                            </ProductImageWrapper>
                        </td> */}
                        <td>
                            <ProductTitle>{dataArray[1]}</ProductTitle>
                            <ProductQuantity>Quantity: {dataArray[2]}</ProductQuantity>
                        </td>
                    </tr>
                </tbody>
            </OrderTable>
        </ProductCardLink>
    )
}

export default OrderProducts