import React from 'react'
import SignIn from '../components/SignIn';

const SignInPage = ({ setAuth }) => {

    return (
        <>
            <SignIn setAuth={setAuth} />
        </>
    )
}

export default SignInPage
