import React from 'react';
import {
    ConfirmTextContainer,
    ConfirmEmailText
} from './CheckEmailElements';

const CheckEmail = () => {

    return (
        <ConfirmTextContainer>
            <ConfirmEmailText>
                Check your email to confirm your request!
            </ConfirmEmailText>
        </ConfirmTextContainer>
    )
}

export default CheckEmail
