import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import Logo from '../../images/logov2.png';
import {
  MainNav,
  MainNavbarContainer,
  MainNavMobileIcon,
  MainNavMenu,
  InputWrapper,
  IconWrapper,
  HomeButton,
  UserLocation,
  HomeWrapper,
  LocationPickerWrapper,
  ProductNumbers
} from './MainNavigationElements';
import { motion } from "framer-motion";
import { BiHome, BiUser, BiCart, BiMap, BiRightArrowAlt } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { RiCake3Line } from "react-icons/ri";
import "../animationStyle.css";
// import LocationPickerData from '../LoactionPickerData';
import useLogout from '../../Hooks/UseLogout';
import { UsersContext } from '../../context/UsersContext';
import { setSearch, geocoder } from "../geocoder/index";
import useSearch from './useSearch';

const MainNavigation = ({ toggle, setAuth }) => {

  const { userAuth } = useContext(UsersContext);

  const username = userAuth.username;

  const logout = useLogout();

  const { handleChange, handleSubmit, _handleKeyDown, values } = useSearch();

  const signOut = async (e) => {
    await logout(e);
    setAuth(false);
  };

  const userProducts = Object.keys(sessionStorage);
  const userProductsArray = [];
  const productsArray = [];
  const quantity = [];
  userProductsArray.push(userProducts)

  userProductsArray[0].map(product => { productsArray.push(JSON.parse(sessionStorage.getItem(product))); })

  productsArray.map(product => {quantity.push(parseInt(product.quantity))})

  var sum = 0;
  for (var i = 0; i < quantity.length; i++) {
    if(typeof quantity[i] == `number`) sum += quantity[i];
  }

  const [cartNumber, setCartNumber] = useState(sum);

  // console.log(cart)

  useEffect(() => {
    var input = JSON.parse(localStorage.getItem('searchInput'));

    if (input !== null) {
      geocoder._setInputValue(input);
    }
      
    // cart
    if (sum < 99 && sum !== 0) {
      setCartNumber(sum);
    } else if (sum > 99) {
      setCartNumber("99+");
    }
  }, []);

  // console.log(cartNumber)

  return (
    <MainNav>
      <MainNavbarContainer>
        <HomeWrapper>
          <motion.div className="LogoAnimation" whileTap={{ scale: 0.9 }}>
            <Link className="MainNavLogo" to="/explore"> <img src={Logo} width="100px" alt="Bakesly" /> </Link>
          </motion.div>
        </HomeWrapper>
        <motion.div className="HomeButtonMainNav" whileTap={{ scale: 0.9 }}>
          <Link className="MainNavLink" to="/explore"><BiHome size={30} color="#f0929f" /></Link>
        </motion.div>
        <InputWrapper onSubmit={handleSubmit}>
          <IconWrapper>
            <BiMap size={25} color="white" />
          </IconWrapper>
          {/* <UserLocation placeholder="New York, NY 10007 New York, NY 10007" /> */}
          <div id="geocoder" ref={setSearch} onKeyDown={_handleKeyDown} name="location" value={values.userLocation} onChange={handleChange} required="required" />
          <HomeButton><motion.button className="HomeMotionButton" whileTap={{ scale: 0.9 }}><BiRightArrowAlt color="white" className="HomeButton" /></motion.button></HomeButton>
        </InputWrapper>
        <MainNavMobileIcon onClick={toggle}>
          <FaBars />
        </MainNavMobileIcon>
        <MainNavMenu>
          <LocationPickerWrapper>
            {/* <LocationPickerData /> */}
          </LocationPickerWrapper>
          {!username ? (
                   <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
                 </motion.div>
          ) : (
            <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
              <Link className="MainNavLink" onClick={e => signOut(e)} to="/signin"><FiLogOut size={30} color="#f0929f" /></Link>
            </motion.div>
          )
          }
          {/* {!username ? (
            <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
              <Link className="MainNavLink" to="/signin"><RiCake3Line size={30} color="#f0929f" /></Link>
            </motion.div>
          ) : (
            <></>
          )
          } */}
          {!username ? (
            <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
              <Link className="MainNavLink" to="/signin"><RiCake3Line size={30} color="#f0929f" /></Link>
            </motion.div>
          ) : (
            <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
              <Link className="MainNavLink" to={"/" + username}><BiUser size={30} color="#f0929f" /></Link>
            </motion.div>
          )
          }
          <motion.div className="NavItem" whileTap={{ scale: 0.9 }}>
            <Link className="MainNavLink" to="/checkout"><BiCart size={30} color="#f0929f" /><ProductNumbers>{cartNumber}</ProductNumbers></Link>
          </motion.div>
        </MainNavMenu>
      </MainNavbarContainer>
    </MainNav>
  )
}

export default MainNavigation
