import React, {useState}  from 'react';
import ReviewProduct from '../components/ReviewProduct';
import MobileMainNav from '../components/MobileMainNav';
import MainNavigation from '../components/MainNavigation';
import { motion } from "framer-motion";
import '../components/animationStyle.css';

const ReviewPage = ({ setAuth }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
        {/* <MobileMainNav isOpen={isOpen} toggle={toggle} setAuth={setAuth} /> */}
        <div className="hideOnUser">
            <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 20
            }}
            >
                <MainNavigation toggle={toggle} setAuth={setAuth} />
            </motion.div>
            </div>
        <ReviewProduct />
        </>
    )
}

export default ReviewPage
