import React from 'react';
import validate from './validate';
import useForm from './useForm';
import {
    SignInContainer,
    SignInLogo,
    UsernameInputWrapper,
    UsernameIconWrapper,
    PasswordInputWrapper,
    PasswordIconWrapper,
    Input,
    HomeBackButton,
    PasswordForgot,
    SignUpWrapper,
    SignInLine,
    TOS,
    SignUpButton,
    Line
} from './SignInElements';
import { BiUser, BiLockAlt, BiHome } from "react-icons/bi";
import { motion } from "framer-motion";
import "../animationStyle.css";
import Logo from '../../images/logov2.png';

const SignIn = ({ setAuth }) => {

const { handleChange, handleSubmit, errors } = useForm(
    validate,
    setAuth
);

return (
    <SignInContainer>
        <HomeBackButton to="/">
            <motion.div className="BackHomeButton" whileTap={{ scale: 0.9 }}>
                <BiHome size={30} color="#f0929f" />
            </motion.div>
        </HomeBackButton>
        <motion.div 
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
        className="SignInAnimation"
        >
                <SignInLogo src={Logo} alt="Bakesly" />
                <form  onSubmit={handleSubmit} noValidate>
                <UsernameInputWrapper>
                    <UsernameIconWrapper>
                        <BiUser size={25} color="#f0929f" />
                    </UsernameIconWrapper>
                    <Input name="emailaddress" onChange={handleChange} placeholder="E-mail" type="email" required="required" />
                    <Line />
                    {errors.emailaddress && <p className="Errorstyle">{errors.emailaddress}</p>}
                </UsernameInputWrapper>
                <PasswordInputWrapper>
                    <PasswordIconWrapper>
                        <BiLockAlt size={25} color="#f0929f" />
                    </PasswordIconWrapper>
                    <Input name="password" onChange={handleChange} type="password" placeholder="Password" required="required" />
                    <Line />
                    {errors.password && <p className="Errorstyle">{errors.password}</p>}
                </PasswordInputWrapper>
                <PasswordForgot to="/forgotpassword">Forgot password?</PasswordForgot>
                <br />
                <motion.button type="submit" className="SignInAnimationButton" whileTap={{ scale: 0.9 }}>Sign in</motion.button>
                <br />
                <SignUpButton to="/signup">
                    <motion.button  className="SignInAnimationButton" whileTap={{ scale: 0.9 }}>Sign up!</motion.button>
                </SignUpButton>
                </form>
                <SignUpWrapper>
                    <SignInLine></SignInLine>
                </SignUpWrapper>
        </motion.div>
        <motion.div className="TOS" whileTap={{ scale: 0.9 }}>
            <TOS to="/termsofservice"><u>Terms of Service</u></TOS>
        </motion.div>
    </SignInContainer>
)
}

export default SignIn