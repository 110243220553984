import { React, useRef, useState } from 'react';
import validate from './validate';
import useForm from './useForm';
import "./formstyle.css";
import "../animationStyle.css";
import {
  BackButtonWrapper
} from './CompleteSignUpElements';
import { motion } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";
import { MdDriveFolderUpload } from "react-icons/md";
import { setSearch } from "../geocoder/index";
import spinner from '../../images/Spinner-1s-200px.gif';

const CompleteSignUp = ({ setAuth }) => {

  const [isChecked, setIsChecked] = useState(false);
  const [delIsChecked, setDelIsChecked] = useState(false);
  const [picIsChecked, setPicIsChecked] = useState(false);

  const fileInputRef = useRef(HTMLInputElement);

  const { handleChange, imagePreviewHandler, handleFileChange, handleSubmit, values, isLoading, setLoading, errors, isFailed, setFailed } = useForm(
    validate,
    setAuth
  );

  const checkHandler = () => {
    setIsChecked(!isChecked);
  }

  if (isChecked === false) {
    values.tos = false;
  } else if (isChecked === true) {
    values.tos = true;
  }

  const delCheckHandler = () => {
    setDelIsChecked(!delIsChecked);
  }

  const picCheckHandler = () => {
    setPicIsChecked(!picIsChecked);
  }

  if (delIsChecked === false) {
    values.delivery = false;
  } else if (delIsChecked === true) {
    values.delivery = true;
  }

  if (picIsChecked === false) {
    values.pickup = false;
  } else if (picIsChecked === true) {
    values.pickup = true;
  }

  const multiHandlers = (e) => {
    imagePreviewHandler(e);
    handleFileChange(e);
  }

  return (
    <div className="Formcontainer">
      <BackButtonWrapper to="/">
        <IoIosArrowBack size={30} color="#f0929f" /> Home
      </BackButtonWrapper>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }}
        className="MainInputWrapperSettings"
      >
        <form onSubmit={handleSubmit} noValidate className="Fromwrapper">
          <div className="Formsectionwrapper">
            <div className="Completeuserimagewrapper">
              <div className="Userpicwrapper">
                <div className="Userimagewrapper">
                  <img id="UserImage" src="#" alt="Profile" />
                  <button className="Profileimagebutton" onClick={(e) => { e.preventDefault(); fileInputRef.current.click(); }} ><MdDriveFolderUpload size={30} color="#7c7c7c" /></button>
                  <input id="ProfileImage" name="profileimage" type="file" ref={fileInputRef} accept="image/png,image/jpeg" onChange={multiHandlers} required="required" />
                  {errors.profileimage && <p className="Errorstyleimage">{errors.profileimage}</p>}
                </div>
              </div>
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">First name*</label>
              <input
                type="text"
                name="firstname"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter your first name"
                value={values.firstname}
                onChange={handleChange}
                required="required"
              />
              {errors.firstname && <p className="Errorstyle">{errors.firstname}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Last name*</label>
              <input
                type="text"
                name="lastname"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter your last name"
                value={values.lastname}
                onChange={handleChange}
                required="required"
              />
              {errors.lastname && <p className="Errorstyle">{errors.lastname}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Business name*</label>
              <input
                type="text"
                name="businessname"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter your business name"
                value={values.businessname}
                onChange={handleChange}
                required="required"
              />
              {errors.businessname && <p className="Errorstyle">{errors.businessname}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Business username*</label>
              <input
                type="text"
                name="username"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter a business username"
                value={values.username}
                onChange={handleChange}
                required="required"
              />
              {errors.username && <p className="Errorstyle">{errors.username}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Business e-mail*</label>
              <input
                type="email"
                name="emailaddress"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter your business e-mail"
                onChange={handleChange}
                required="required"
              />
              {errors.emailaddress && <p className="Errorstyle">{errors.emailaddress}</p>}
            </div>
          </div>
          <div className="Formsectionwrapper SectionWrapperMobileRight">
            <div className="Inputwrapper">
              <label className="Inputlabel">Products ready between*</label>
              <select
                type="select"
                name="mintime"
                className="TimeInputfield"
                autoComplete="new-password"
                value={values.mintime}
                onChange={handleChange}
                required="required">
                <option defaultValue>Select min ready time</option>
                <option value="0 hour">0 hour</option>
                <option value="1 hour">1 hour</option>
                <option value="2 hours">2 hours</option>
                <option value="3 hours">3 hours</option>
                <option value="4 hours">4 hours</option>
                <option value="5 hours">24 hours</option>
                <option value="6 hours">48 hours</option>
                <option value="7 hours">72 hours</option>
                <option value="8 hours">1 week</option>
              </select>
              &nbsp;and&nbsp;
              <select
                type="select"
                name="maxtime"
                className="TimeInputfield"
                autoComplete="new-password"
                value={values.maxtime}
                onChange={handleChange}
                required="required">
                <option defaultValue>Select max ready time</option>
                <option value="1 hour">1 hour</option>
                <option value="2 hours">2 hours</option>
                <option value="3 hours">3 hours</option>
                <option value="4 hours">4 hours</option>
                <option value="5 hours">24 hours</option>
                <option value="6 hours">48 hours</option>
                <option value="7 hours">72 hours</option>
                <option value="8 hours">1 week</option>
              </select>
              {errors.mintime && <p className="Errorstyle">{errors.mintime}</p>}
              {errors.maxtime && <p className="Errorstyle">{errors.maxtime}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Business phone number*</label>
              <input
                type="text"
                name="phonenumber"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter your business phone number"
                value={values.phonenumber}
                onChange={handleChange}
                required="required"
              />
              {errors.phonenumber && <p className="Errorstyle">{errors.phonenumber}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Business bio*</label>
              <textarea
                type="text"
                name="bio"
                className="Inputarea"
                autoComplete="new-password"
                placeholder="Enter your business bio"
                value={values.bio}
                onChange={handleChange}
                required="required"
                maxLength="75"
              />
              {errors.bio && <p className="Errorstyle">{errors.bio}</p>}
            </div>
            <div className="Inputlocationwrapper">
              <label className="Inputlabel">Search and select your most detailed business address and location*</label>
              <div id="geocoder" ref={setSearch} name="location" value={values.location} onChange={handleChange} required="required" />
              {errors.location && <div className="Errorstyle">{errors.location}</div>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel Addresslabel">Business street name and number*</label>
              <input
                type="text"
                name="streetname"
                className="Inputstreetname"
                autoComplete="new-password"
                placeholder="Business street name"
                value={values.streetname}
                onChange={handleChange}
                required="required"
              />
              <input
                type="text"
                name="streetnumber"
                className="Inputstreetnumber"
                autoComplete="new-password"
                placeholder="Nr."
                value={values.streetnumber}
                onChange={handleChange}
                required="required"
              />
              {errors.streetname && <div className="Errorstyle">{errors.streetname}</div>}
              {errors.streetnumber && <p className="Errorstyle">{errors.streetnumber}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Password*</label>
              <input
                type="password"
                name="password"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter your password"
                onChange={handleChange}
                required="required"
              />
              {errors.password && <p className="Errorstyle">{errors.password}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Confirm password*</label>
              <input
                type="password"
                name="password2"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Confirm your password"
                onChange={handleChange}
                required="required"
              />
              {errors.password2 && <p className="Errorstyle">{errors.password2}</p>}
            </div>
          </div>
          <div className="Formsectionwrapper SectionWrapperMobileNone">
            <div className="Inputwrapper">
              <label className="Inputlabel">Choose your service*</label>
              <div className="Servicediv" >
                <input type="checkbox" name="delivery" value={values.delivery} checked={delIsChecked} onChange={delCheckHandler} required="required" /> <label className="ServiceCheckbox">Delivery</label>&nbsp;
                <input type="checkbox" name="pickup" value={values.pickup} checked={picIsChecked} onChange={picCheckHandler} required="required" /> <label className="ServiceCheckbox">Pick-up</label>
              </div>
              {errors.delivery && <p className="Errorstyle">{errors.delivery}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Selling distance (kilometers / miles)*</label>
              <select
                type="select"
                name="sellingdistance"
                className="Inputfield"
                autoComplete="new-password"
                value={values.sellingdistance}
                onChange={handleChange}
                required="required"
              >
                <option default>Select a distance</option>
                <option value="500">0.5 km / 0.3 mi</option>
                <option value="1000">1.0 km / 0.6 mi</option>
                <option value="1500">1.5 km / 0.9 mi</option>
                <option value="2000">2.0 km / 1.2 mi</option>
                <option value="5000">5.0 km / 3.1 mi</option>
                <option value="7000">7.0 km / 4.3 mi</option>
                <option value="10000">10.0 km / 6.2 mi</option>
                <option value="15000">15.0 km / 9.3 mi</option>
                <option value="20000">20.0 km / 12.4 mi</option>
                <option value="25000">25.0 km / 15.5 mi</option>
              </select>
              {errors.sellingdistance && <p className="Errorstyle">{errors.sellingdistance}</p>}
            </div>
            <div className="Inputwrapper">
              <label className="Inputlabel">Min. price to order for delivery*</label>
              <div>
                <input
                  type="number"
                  name="mindeliveryprice"
                  className="Inputcurrency"
                  autoComplete="new-password"
                  placeholder="Enter min. amount"
                  value={values.mindeliveryprice}
                  onChange={handleChange}
                  required="required"
                />
                <select
                  type="select"
                  name="currency"
                  className="Currencyoptions"
                  autoComplete="new-password"
                  value={values.currency}
                  onChange={handleChange}
                  required="required"
                >
                  <option default>Currency</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="AUD">AUD</option>
                  <option value="CAD">CAD</option>
                  <option value="NZD">NZD</option>
                  <option value="GBP">GBP</option>
                  <option value="JPY">JPY</option>
                </select>
                {errors.mindeliveryprice && <p className="Errorstyle">{errors.mindeliveryprice}</p>}
                {errors.currency && <p className="Errorstyle">{errors.currency}</p>}
              </div>
            </div>
            {/* <div className="Inputwrapper">
              <label className="Inputlabel">Add your PayPal payment email to get paid, <a href="https://www.paypal.com/li/smarthelp/article/how-do-i-get-paid-using-paypal-faq1750" target="blank">click here for more info</a>*</label>
              <input
                type="email"
                name="paypalemail"
                className="Inputfield"
                autoComplete="new-password"
                placeholder="Enter your PayPal e-mail"
                value={values.paypalemail}
                onChange={handleChange}
                required="required"
              />
              {errors.paypalemail && <p className="Errorstyle">{errors.paypalemail}</p>}
            </div> */}
            <div className="Inputwrapper">
              <label className="Inputlabel">Opening hours* (0-24h)</label>
              <table className="Opentable">
                <tbody>
                  <tr>
                    <td>Mon</td>
                    <td><input className="Inputtime" name="mon_open" value={values.mon_open} onChange={handleChange} type="time" required="required" /> to <input className="Inputtime" name="mon_closed" value={values.mon_closed} onChange={handleChange} type="time" required="required" /></td>
                  </tr>
                  <tr>
                    <td>Tue</td>
                    <td><input className="Inputtime" name="tue_open" value={values.tue_open} onChange={handleChange} type="time" required="required" /> to <input className="Inputtime" name="tue_closed" value={values.tue_closed} onChange={handleChange} type="time" required="required" /></td>
                  </tr>
                  <tr>
                    <td>Wed</td>
                    <td><input className="Inputtime" name="wed_open" value={values.wed_open} onChange={handleChange} type="time" required="required" /> to <input className="Inputtime" name="wed_closed" value={values.wed_closed} onChange={handleChange} type="time" required="required" /></td>
                  </tr>
                  <tr>
                    <td>Thu</td>
                    <td><input className="Inputtime" name="thu_open" value={values.thu_open} onChange={handleChange} type="time" required="required" /> to <input className="Inputtime" name="thu_closed" value={values.thu_closed} onChange={handleChange} type="time" required="required" /></td>
                  </tr>
                  <tr>
                    <td>Fri</td>
                    <td><input className="Inputtime" name="fri_open" value={values.fri_open} onChange={handleChange} type="time" required="required" /> to <input className="Inputtime" name="fri_closed" value={values.fri_closed} onChange={handleChange} type="time" required="required" /></td>
                  </tr>
                  <tr>
                    <td>Sat</td>
                    <td><input className="Inputtime" name="sat_open" value={values.sat_open} onChange={handleChange} type="time" required="required" /> to <input className="Inputtime" name="sat_closed" value={values.sat_closed} onChange={handleChange} type="time" required="required" /></td>
                  </tr>
                  <tr>
                    <td>Sun</td>
                    <td><input className="Inputtime" name="sun_open" value={values.sun_open} onChange={handleChange} type="time" required="required" /> to <input className="Inputtime" name="sun_closed" value={values.sun_closed} onChange={handleChange} type="time" required="required" /></td>
                  </tr>
                </tbody>
              </table>
              {(errors.mon_open || errors.mon_closed || errors.tue_open || errors.tue_closed || errors.wed_open || errors.wed_closed || errors.thu_open || errors.thu_closed || errors.fri_open || errors.fri_closed || errors.sat_open || errors.sat_closed || errors.sun_open || errors.sun_closed) && <p className="Errorstyle">{errors.mon_open}</p>}
            </div>
            <div className="Inputwrapper">
              <div className="Checkboxwrapper">
                <input
                  type="checkbox"
                  name="tos"
                  className="Inputcheckbox"
                  required="required"
                  value={values.tos}
                  checked={isChecked}
                  onChange={checkHandler}
                />
                <label className="Checkboxlabel">Accept the <a href="http://localhost:3001/termsofservice">terms of service</a>.*</label>
                {errors.tos && <p className="Errorstyle">{errors.tos}</p>}
              </div>
            </div>
            {
              !isLoading ? (
                <motion.button className="Completebutton" whileTap={{ scale: 0.9 }} type="submit">
                  Complete account
                </motion.button>
              ) : (
                <img src={spinner} alt="Loading" width="48" height="48" />
              )
            }
            {
              !isFailed ? (
                <></>
              ) : (
                <p>Account completion failed, please try again or refresh the page.</p>
              )
            }
          </div>
        </form>
      </motion.div>
    </div >
  );
};

export default CompleteSignUp;
