import React, {useState}  from 'react';
import ResetPass from '../components/ResetPass';

const ResetPassPage = () => {
    return (
        <ResetPass />
    )
}

export default ResetPassPage
