import React from 'react';
import ConfirmEmail from '../components/ConfirmEmail';

const ConfirmEmailPage = () => {
    return (
        <ConfirmEmail/>
    )
}

export default ConfirmEmailPage
