import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const BackButtonWrapper = styled(LinkRouter)`
    width: fit-content;
    position: absolute;
    left: 25px;
    top: 25px;
    font-size: 18px;
    color: black !important;
`;