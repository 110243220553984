import React from 'react';
import {
    AboutContainer,
    AboutBackground,
    AboutH1,
    AboutP,
    AboutContentWrapper,
    InstaLink
} from './AboutElements';
import { FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion";
import "../animationStyle.css";

const About = () => {
    return (
        <AboutContainer>
            <AboutBackground>
            </AboutBackground>
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
                className="AboutAnimation"
            >
                <AboutContentWrapper>
                    <AboutP> 
                        Bakesly is a platform for all your baked goods, pastries, confectionery and more from local authentic shops. A wide range of products is offered through Bakesly by various shops to fulfill all your cravings, events and celebrations!  
                    </AboutP>
                    <AboutP> 
                        Bakesly is built on three principles. As a baker or confectioner on the platform you are in control from A to Z, from selling to delivery without third party involvement. Upholding transparency towards shops and users of the services is the second key principle that drives us at Bakesly. The most important principle of all is that Bakesly is community driven, helping you succeed through collaborating together. <a href='https://bakesly.com/signup'>Sign up today!</a>   
                    </AboutP>
                </AboutContentWrapper>

            </motion.div>
            <motion.div className="InstaButton" whileTap={{ scale: 0.9 }}>
                <a href="https://www.instagram.com/bakesly/">
                    <FaInstagram size={40} color="#f0929f" />
                </a>
            </motion.div>
        </AboutContainer>
    )
}

export default About;
