import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
    ReviewContainer,
    ReviewWrapper,
    ReviewTitle,
    ReviewInput,
    RatingWrapper,
    RatingNameProduct,
    ReviewButton,
    TOS
} from './ReviewProductElements.js';
// import Rating from '@material-ui/lab/Rating';
import Rating from '@mui/material/Rating';
import { motion } from "framer-motion";
import "../animationStyle.css";
import MobileAddressBar from '../MobileAddressBar';
import UserData from '../../API/UserFinder';
import validate from './validate';
import useForm from './useForm';
import "../animationStyle.css";

const ReviewProduct = () => {
    const [rating, setRating] = React.useState(0);
    const [hover, setHover] = React.useState(-1);
    const [data, setData] = useState('');
    const { token } = useParams();

        // get temp review data
    // insert review

    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                const getReview = await UserData.get('/reviewdata/' + token).then(data => {
                    setData(data.data)
                });

            } catch (err) {
                // window.location.replace("/");
                console.error(err.message);
            }
        };

        fetchReviewData();
    }, [token]);

    const { handleChange, handleSubmit, values, errors, } = useForm(
        validate,
        rating,
        hover,
        token
    );

    return (
        <ReviewContainer>
            <MobileAddressBar></MobileAddressBar>
            <ReviewWrapper>
                <ReviewTitle>Rate your order:</ReviewTitle>
                <form onSubmit={handleSubmit} noValidate>
                    <RatingWrapper>
                        <Rating
                            name="hover-feedback"
                            precision={0.5}
                            value={rating}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                            size="large"
                        />
                        <RatingNameProduct>{data.shop}</RatingNameProduct>
                    </RatingWrapper>
                    <ReviewInput name="review" placeholder="Give your review" autoComplete="new-password" value={values.review} onChange={handleChange} type="text" required="required" />
                    {errors.review && <p className="Errorstyle">{errors.review}</p>}
                    <ReviewButton>
                        <motion.button className="ReviewSubmitButton" whileTap={{ scale: 0.9 }} type='submit'>Submit</motion.button>
                    </ReviewButton>
                </form>
            </ReviewWrapper>
            <motion.div className="TOS" whileTap={{ scale: 0.9 }}>
                <TOS to="/termsofservice"><u>Terms of Service</u></TOS>
            </motion.div>
        </ReviewContainer>
    )
}

export default ReviewProduct
