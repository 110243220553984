import { useState, useContext } from 'react';
import { geoLocation, geoPlace } from "../geocoder/index";
import useAuth from '../../Hooks/useAuth';
import { UsersContext } from '../../context/UsersContext';

const useForm = (validate, userData) => {

  const { userAuth } = useContext(UsersContext);

  var userid;

  if (!userAuth.user) {
    userid = userAuth.id;
  } else if (!userAuth.id) {
    userid = userAuth.user;
  }

  const [values, setValues] = useState({
    userid: userid,
    profileimage: '',
    firstname: userData.firstname,
    lastname: userData.lastname,
    businessname: userData.businessname,
    emailaddress: userData.emailaddress,
    phonenumber: userData.phonenumber,
    bio: userData.bio,
    location: userData.location,
    streetname: userData.streetname,
    streetnumber: userData.streetnumber,
    place: userData.place,
    sellingdistance: userData.sellingdistance,
    currency: userData.currency,
    mindeliveryprice: userData.mindeliveryprice,
    password: '',
    password2: '',
    // paypalemail: userData.paypalemail,
    mon_open: userData.mon_open,
    mon_closed: userData.mon_closed,
    tue_open: userData.tue_open,
    tue_closed: userData.tue_closed,
    wed_open: userData.wed_open,
    wed_closed: userData.wed_closed,
    thu_open: userData.thu_open,
    thu_closed: userData.thu_closed,
    fri_open: userData.fri_open,
    fri_closed: userData.fri_closed,
    sat_open: userData.sat_open,
    sat_closed: userData.sat_closed,
    sun_open: userData.sun_open,
    sun_closed: userData.sun_closed,
    tos: userData.tos,
    username: userData.username,
    mintime: userData.mintime,
    maxtime: userData.maxtime,
    delivery: userData.delivery,
    pickup: userData.pickup,
  });
  const [selectedImage, setSelectedImage] = useState(userData.profileimage);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const axiosAuth = useAuth();

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    setSelectedImage({
      ...selectedImage,
      file
    });
  }

  const imagePreviewHandler = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const inputId = document.getElementById("ProfileImage");
    const imageId = document.getElementById("UserImage");

    try {
      if (file === undefined) {
        imageId.src = null;
        inputId.value = null;
      } else {
        reader.onloadend = () => {
          baseImage(reader.result)
        };

        reader.readAsDataURL(file);

        const baseImage = (encodedImage) => {
          imageId.src = encodedImage;
        }

      // } else {
      //   alert("Selected image file was to big, max. 1.5MB");
      //   imageId.src = null;
      //   inputId.value = null;
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    try {

      if (geoLocation !== undefined && geoPlace !== undefined) {
        values.location = geoLocation;
        values.place = geoPlace;
      } else {
        values.location = userData.location;
        values.place = userData.place;
      }

      setErrors(validate(values, selectedImage));

      const sendResponse = async (baseImage) => {

        if (baseImage !== undefined) {
          values.profileimage = baseImage;
        } else {
          values.profileimage = userData.profileimage;
        }

        await axiosAuth.post('/settings', values).then(data => {
          if (data.data === "Username exist") {
            values.username = "Username already exist";
            setErrors(validate(values, selectedImage));
            setLoading(false);
          } else if (data.data === "Invalid Password") {
            values.password = "Invalid Password";
            setErrors(validate(values, selectedImage));
            setLoading(false);
          } else if (data.data === "Success") {
            window.location.reload();
          } else if (data.data === "User not known" || data.data === "Try something else") {
            setLoading(false);
            setFailed(true);
          }
        }).catch(error => {
          console.error('Error:', error)
        });
      }

      if (Object.keys(validate(values, selectedImage)).length === 0) {
        setLoading(true);
        if (selectedImage !== userData.profileimage) {
          const reader = new FileReader();
          reader.readAsDataURL(selectedImage.file);
          reader.onloadend = () => {
            sendResponse(reader.result);
          };
        } else {
          sendResponse();
        }

        setTimeout(function () {
          setFailed(true);
          setLoading(false);
        }, 15000);
      } else {
        setLoading(false);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, imagePreviewHandler, handleFileChange, handleSubmit, values, selectedImage, isLoading, setLoading, errors, isFailed, setFailed };
};

export default useForm;