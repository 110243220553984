import React, { useEffect } from 'react';
import {
    SearchBarNavigation,
    IconWrapper,
    UserLocation,
    ExploreButton,
    InputWrapper,
    HomeButton
  } from './MobileAddressBarElements';
import { BiMap, BiRightArrowAlt } from "react-icons/bi";
import { motion } from "framer-motion";
import { setSearch, geocoder } from "../geocoder/index";
import useSearch from './useSearch';

const MobileAddressBar = () => {

  const { handleChange, handleSubmit, _handleKeyDown, values } = useSearch();

  useEffect(() => {
    var input = JSON.parse(localStorage.getItem('searchInput'));

    if (input !== null) {
      geocoder._setInputValue(input);
    }
  }, []);

    return (
        // <SearchBarNavigation>
        //   <IconWrapper>
        //     <BiMap size={25} color="white" />
        //   </IconWrapper>
        //   <UserLocation placeholder="New York, NY 10007 New York, NY 10007" />
        //   <ExploreButton to="/explore"><motion.button className="HomeMotionButton" whileTap={{ scale: 0.9 }}><BiRightArrowAlt color="white" className="HomeButton"/></motion.button></ExploreButton>
        // </SearchBarNavigation>

      <InputWrapper onSubmit={handleSubmit}>
      <IconWrapper>
        <BiMap size={25} color="white" />
      </IconWrapper>
      {/* <div id="geocoder" ref={setSearch} onKeyDown={_handleKeyDown} name="location" value={values.userLocation} onChange={handleChange} required="required" /> */}
      <HomeButton><motion.button className="HomeMotionButton" whileTap={{ scale: 0.9 }}><BiRightArrowAlt color="white" className="HomeButton" /></motion.button></HomeButton>
      </InputWrapper>
    )
}

export default MobileAddressBar
