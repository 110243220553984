import styled from 'styled-components';

export const ConfirmTextContainer = styled.div`
width:100%;
height: 100%;
position: absolute;
text-align: center;
`;

export const ConfirmEmailText = styled.div`
position: absolute;
margin: auto;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 90%;
height: fit-content;
font-size: 22px;
`;

export const ConfirmEmailsIcons = styled.div`

`;