export default function validate(values) {
    let errors = {};
  
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    else if (!/^[A-Za-z]+$/.test(values.firstName)) {
      errors.firstName = 'Enter a valid first name';
    }
    else if (values.firstName.length < 2) {
      errors.firstName = 'First name is to short';
    }
    else if (values.firstName.length > 30) {
      errors.firstName = 'First name is to long';
    }

    if (!values.lastName) {
      errors.lastName = 'Last name is required';
    }
    else if (!/^[A-Za-z]+$/.test(values.lastName)) {
      errors.lastName = 'Enter a valid last name';
    }
    else if (values.lastName.length < 2) {
      errors.lastName = 'Last name is to short';
    }
    else if (values.lastName.length > 30) {
      errors.lastName = 'Last name is to long';
    }

    if (!values.businessName) {
      errors.businessName = 'Business name is required';
    }
    else if (!/^[a-zA-Z0-9*&']+( [a-zA-Z0-9*&']+)*$/.test(values.businessName)) {
      errors.businessName = 'Enter a valid business name';
    }
    else if (values.businessName.length < 3) {
      errors.businessName = 'Business name is to short';
    }
    else if (values.businessName.length > 25) {
      errors.businessName = 'Business name is to long';
    }
  
    if (!values.emailAddress.trim()) {
      errors.emailAddress = 'Email is required';
    } 
    else if (values.emailAddress === "User already exist") {
      errors.emailAddress = 'User already exist';
    }
    else if (values.emailAddress === "User request already received") {
      errors.emailAddress = 'User request already received';
    }
    else if (values.emailAddress === "Account is being verified") {
      errors.emailAddress = 'Email sent to complete account';
    }
    else if (values.emailAddress === "User banned") {
      errors.emailAddress = 'User banned';
    }
    else if (!/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(values.emailAddress)) {
      errors.emailAddress = 'Email address is invalid';
    }
    else if (values.emailAddress.length < 4) {
      errors.emailAddress = 'Email address to short';
    }
    else if (values.emailAddress.length > 50) {
      errors.emailAddress = 'Email address is to long';
    }

    if (!values.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } 
    else if (!/^[0-9]+$/.test(values.phoneNumber)) {
      errors.phoneNumber = 'Phone number is invalid';
    }
    else if (values.phoneNumber.length < 4) {
      errors.phoneNumber = 'Phone number to short';
    }
    else if (values.phoneNumber.length > 20) {
      errors.phoneNumber = 'Phone number is to long';
    }

    if (!values.address) {
      errors.address = 'Address is required';
    } 
    else if (!/^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/.test(values.address)) {
      errors.address = 'Address is invalid';
    }
    else if (values.address.length < 3) {
      errors.address = 'Address is to short';
    }
    else if (values.address.length > 40) {
      errors.address = 'Address is to long';
    }

    if (!values.description) {
      errors.description = 'Description is required';
    }
    else if (!/^[a-zA-Z0-9,.*&']+( [a-zA-Z0-9,.*&']+)*$/.test(values.description)) {
      errors.description = 'Description is invalid';
    }
    else if (values.description.length < 10) {
      errors.description = 'Description is to short';
    }
    else if (values.description.length > 110) {
      errors.description = 'Description is to long';
    }

    if (!values.socialOne) {
      errors.socialOne = 'Instagram link is required';
    }

    if (values.tos === false) {
      errors.tos = 'Checkbox is required';
    }

    return errors;
  }