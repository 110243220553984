import React, { useEffect } from 'react';
import UserData from '../../API/UserFinder';

// delete

const Order = () => {

    useEffect(() => {

        const baseURL =
        process.env.NODE_ENV === "production"
            ? "/api/"
            : "http://localhost:3001/api/";

        const getOrder = async () => {
            try {
                await fetch(baseURL+"order", {
                    credentials: 'same-origin',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                })
            } catch (err) {
                console.error(err.message);
            }
        };

        getOrder();

    }, []);

    return (
        <div>order</div>
    )
}

export default Order