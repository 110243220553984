import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const ProductCardLink = styled(LinkRouter)`
    text-decoration: none;
    color: black;
    height: inherit;
    display: inherit;
    word-break: break-all;

    &:hover {
        color: black;
    }

    // @media screen and (max-width: 480px) {
    //     word-break: break-all;
    // }
`;

export const OrderTable = styled.table`
    font-size: 15px;
    margin-top: 10px;

    tbody, td, tfoot, th, thead, tr {
        border:none;
    }
`;

export const ProductImageWrapper = styled.div`
    width: 200px;
`;

export const ProductImage = styled.img`
    object-fit: cover;
    width: 190px;
    height: 100px;
`;

export const ProductTitle = styled.div`
 
`;

export const ProductQuantity = styled.div`
 
`;
