import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {
    ConfirmTextContainer,
    ConfirmEmailText
} from './DeletedElements';
import useAuth from '../../Hooks/useAuth';

const ConfirmDelete = () => {

    const [text, setText] = useState("");
    const axiosAuth = useAuth();
    const { token } = useParams();

    useEffect(() => {
        const fetchConfirmDelete = async () => {
            try {
                const getConfirmDelete = await axiosAuth.get('/confirmdelete/' + token).then(data => {
                    if (data.data === "true") {
                        setText("Account has been deleted.")
                    } else {
                        setText("Account deletion has failed please try again, you need to be logged in.")
                    }
                });
            } catch (err) {
                setText("Account deletion has failed please try again, you need to be logged in.")
                console.error(err.message);
            }
        };

        fetchConfirmDelete();
    }, [token]);

    return (
        <ConfirmTextContainer>
            <ConfirmEmailText>
                {text}
            </ConfirmEmailText>
        </ConfirmTextContainer>
    )
}

export default ConfirmDelete
