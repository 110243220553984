import { React, useState, useContext, useEffect } from 'react';
import UserSettings from '../components/UserSettings';
import { UsersContext } from '../context/UsersContext';
import useAuth from '../Hooks/useAuth';

const UserSettingsPage = () => {

    const [userData, setUserData] = useState([]);

    const axiosAuth = useAuth();

    const { userAuth } = useContext(UsersContext);

    const username = userAuth.username;

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchSettingsData = async () => {
            try {
                const getData = await axiosAuth.get('/settingsdata', {
                    headers: {
                        user: userAuth.user
                    }
                });

                isMounted && setUserData(...userData, getData.data.data.userdata);

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchSettingsData();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    return (
        <>
            {userData.map(userData =>
                <UserSettings key={userData.username} userData={userData} />
            )}
        </>
    )
}

export default UserSettingsPage;
