import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const AboutContainer = styled.div`
    width: 100%;   
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export const AboutBackground = styled.div`
    width: 50%;
    height: 100%;
    border-radius: 0 50% 50% 0;
    background: linear-gradient(314deg, rgba(182,216,243,1) 4%, rgba(182,216,243,1) 10%, rgba(245,206,224,1) 100%);
    float: left;
    animation: fadeIn ease 0.5s;

    @keyframes fadeIn {
        0% { 
            opacity: 0; 
        } 
        100% { 
            opacity: 1; 
         } 
     } 

     @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const AboutContentWrapper = styled.div`
    height: fit-content;
    width: fit-content;
    margin: auto;
    display: table-cell;
    vertical-align: middle;
    padding: 50px;
    
    @media (max-width:375px)  {
        padding-top: 100px;
    }

`;

export const AboutH1 = styled.h1`
    font-size: 39px;
    font-family: Inter;
    background: linear-gradient(314deg, rgba(182,216,243,1) 4%, rgba(182,216,243,1) 10%, rgba(245,206,224,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 300;
    color: white;

    @media (max-width:480px)  {
        font-size: 27px;
    }

    @media (max-width:320px)  {
        font-size: 23px;
    }
`;

export const AboutP = styled.p`
    color: black;
    font-size: 16px;
    margin-top: 50px;

    @media (max-width:320px)  {
        font-size: 14px;
        margin-top: 30px;
    }
`;

export const InstaLink = styled(LinkRouter)`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #f0929f;
  }
`;