import React, {useState}  from 'react';
import ConfirmDelete from '../components/Deleted';

const ConfirmDeletePage = () => {
    return (
        <ConfirmDelete />
    )
}

export default ConfirmDeletePage
