import styled from 'styled-components';

export const NewPostContainer = styled.div`

`;


export const PopupTitle = styled.div`
    font-size: 25px;
    background: linear-gradient(314deg, rgba(182,216,243,1) 4%, rgba(182,216,243,1) 10%, rgba(245,206,224,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
    width: fit-content;
`;


export const InputWrapper = styled.div`
    margin-top: 20px;
`;

export const NewPostButtons = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    width: fit-content;
    float: right;
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 300px;
    background-color: #f5f5f5; 
`;

export const InputContainer = styled.form`

`;

export const OrderQuantity = styled.input`
    font-size: 17px;
    width: 80px;
    height: 45px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    user-select: none;
    margin-left: 15px;
    color: #757575;
    background: #f5f5f5;

    @media (max-width:480px)  {
        margin-left: 0;
        margin-top: 10px;
    }
`;

export const MinOrder = styled.div`
    margin-top: 20px;
    font-size: 15px;

    @media (max-width:480px)  {
        text-align: initial;
    }
`;

export const Label = styled.div`
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 8px;
`;

export const Input = styled.input`
    width: 240px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputCurrency = styled.input`
    width: 240px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputCurrencyWrapper = styled.div`
    width: 240px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputTags = styled.div`
    width: 240px;
    border: none;
    padding-left: 10px;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputSectionWrapper = styled.div`
    width: fit-content;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
`;

export const Textarea = styled.textarea`
    width: 240px;
    height: 90px;
    resize: none;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    padding: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const SelectInput = styled.select`
    width: 240px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const ServiceCost = styled.table`
    margin-top: 15px;
    font-size: 12px;
`;

export const Tags = styled.div`
    margin-top: 5px;
    height: 20px;
`;

export const CheckboxWrapper = styled.div`
    height: 40px;
`;

export const LabelCheckbox = styled.div`
    margin-left: 4px;
    font-size: 14px;
    padding: 4px;
`;

export const CheckboxDiv = styled.div`
    display: flex;
    float: left;
    padding: 10px;
`;

export const InputCheckbox = styled.input`
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 10px;
    outline: none;
`;

export const SelectCurrency = styled.select`
    width: 110px;
    height: 40px;
    border: none;
    background: #f5f5f5;
    outline: none;
    border-radius: 0px 10px 10px 0px;
    font-size: 14px;
    color: #757575;
`;

export const MaxPost = styled.div`
    width: 350px;
    margin-top: 80px;
`;

export const PostFailed = styled.p`
    width: 197px;
`;

export const Loading = styled.div`
    width: 400px;
`;