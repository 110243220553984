import React from 'react';
import {
  TopbarContainer,
  Icon,
  CloseIcon,
  TopbarWrapper,
  TopbarMenu,
  TopbarLink
} from './TopbarElements';

const Topbar = ({ isOpen, toggle }) => {
  return (
    <TopbarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <TopbarWrapper>
        <TopbarMenu>
          <TopbarLink to="/about">Bakeswhat?</TopbarLink>
          <TopbarLink to="/signup">Become a baker</TopbarLink>
          <TopbarLink to="/signin">Sign in</TopbarLink>
        </TopbarMenu>
      </TopbarWrapper>
    </TopbarContainer>
  );
}

export default Topbar;
