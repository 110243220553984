import styled from 'styled-components';

export const BakerCardContainer = styled.div`
    box-shadow: 0px 1px 5px 0px #0000001c;
    border-radius: 10px;
    position: relative;
    display: inline-table;
    margin: 10px;
    width: 300px;
    height: 360px;
    background: white;
`;

export const BakerCardContents = styled.div`
    margin: 10px;
`;

export const BakerCardImageWrapper = styled.div`
    height: 280px;
    background: white;
`;

export const BakerCardImage = styled.img`
object-fit: cover;
    width: 300px;
    height: inherit;
    display: block;
`;

export const BakerBio = styled.div`
    font-size: 14px;
    margin-top: 5px;
    width: 280px;
    overflow-wrap: break-word;
`;

export const DisplayName = styled.div`
    margin: 10px;
`;

export const UserVerified = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
`;