import React, { useEffect, useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CompleteRes from "../CompleteRes";

const Complete = () => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const shop = localStorage.getItem('shop');

    const baseURL =
        process.env.NODE_ENV === "production"
          ? "/api/"
          : "http://localhost:3001/api/";
          
    try {
      const fetchStripe = async () => {
        await fetch(baseURL+"stripeconfig", {
          credentials: 'same-origin',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            user: shop
          },
        }).then(res => res.json()).then(async (result) => {
          setStripePromise(loadStripe(result.publishableKey, {
            stripeAccount: result.stripID
          }));
        });
      }
      fetchStripe();
    } catch (err) {
      console.error(err.message);
    }
  }, []);

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CompleteRes/>
        </Elements>
      )}
    </>
  )
}

export default Complete