import React from 'react';
import CompleteSignUp from '../components/CompleteSignUp';

const CompleteSignUpPage = ({ setAuth }) => {
    return (
            <CompleteSignUp setAuth={setAuth} />
    )
}

export default CompleteSignUpPage
