import React, { useState, useRef, useEffect } from 'react';
import validate from './validate';
import useForm from './useForm';
import {
    EditPostContainer,
    PopupTitle,
    InputWrapper,
    EditPostButtons,
    ImageContainer,
    OrderQuantity,
    MinOrder,
    Label,
    Input,
    InputCurrency,
    InputCurrencyWrapper,
    InputSectionWrapper,
    Textarea,
    SelectInput,
    ServiceCost,
    InputTags,
    CheckboxWrapper,
    CheckboxDiv,
    InputCheckbox,
    LabelCheckbox,
    SelectCurrency,
    PostFailed
} from './EditPostElements';
import { motion } from "framer-motion";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import TextField from '@mui/material/TextField';
import "../animationStyle.css";
import spinner from '../../images/Spinner-1s-200px.gif';
import UserData from '../../API/UserFinder';
import { FaInfo } from 'react-icons/fa';

const EditPost = ({ close, product, user }) => {

    const [delIsChecked, setDelIsChecked] = useState(product.delivery);
    const [picIsChecked, setPicIsChecked] = useState(product.pickup);
    const [isDisable, setDisable] = useState(true);
    const [tags, setTags] = useState([]);

    const fileInputRef = useRef(HTMLInputElement);

    const { handleChange, handleFileChange, handleSubmit, values, errors, selectedImages, setSelectedImages, imageValidate, setImageValidate, isLoading, setLoading, isPosted, setPosted, maxPosts, setMaxPosts, deletePost, selectedTags, setSelectedTags, isFailed, setFailed } = useForm(
        validate,
        product
    );

    const delCheckHandler = () => {
        setDelIsChecked(!delIsChecked);
    }

    const picCheckHandler = () => {
        setPicIsChecked(!picIsChecked);
    }

    var disabled = "";

    if (delIsChecked === false) {
        values.delivery = false;
        disabled = "disabled";
    } else if (delIsChecked === true) {
        values.delivery = true;
    }

    if (picIsChecked === false) {
        values.pickup = false;
    } else if (picIsChecked === true) {
        values.pickup = true;
    }

    if (values.price !== undefined && values.deliveryprice !== undefined) {
        const total = +values.price + +values.deliveryprice;
        const servicecost = total / 100 * 15;

        if (user !== 'JPY'){
            values.cost = servicecost.toFixed(2)
        } else {
            values.cost = servicecost.toFixed(0)
        }
    }

    const deleteImage = (image, index) => () => {
        setSelectedImages(selectedImages.filter((_, i) => index !== i));
        setImageValidate(imageValidate.filter((_, i) => index !== i));
    }

    const onInputClick = (e) => {
        e.target.value = '';
    }

    useEffect(() => {

        values.jpy = user;

        const fetchTags = async () => {
            try {
                const getTag = await UserData.get('/tags');
                setTags(getTag.data.data.tag)
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchTags();

        const fetchProductTags = async () => {
            try {
                const getProductTags = await UserData.get('/producttags', {
                    headers: {
                        data: product.product_tags
                    }
                });

                setSelectedTags(getProductTags.data.data.tag)

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchProductTags();

        if (isPosted === true) {
            close();
        }
    }, [isPosted, close]);

    const tagsArray = [];

    tags.map(tag => {
        tagsArray.push(tag)
    })

    const selectedtagsArray = [];

    selectedTags.map(selectedtag => {
        selectedtagsArray.push(selectedtag)
    })

    return (
        <EditPostContainer>
            <PopupTitle>Post</PopupTitle>
            <form onSubmit={handleSubmit} noValidate>
                <InputSectionWrapper>
                    <InputWrapper>
                        <Label>Product name*</Label>
                        <Input type="text" name="title" maxLength="60" autoComplete="new-password" value={values.title} onChange={handleChange} required="required" />
                        {errors.title && <p className="Errorstyle">{errors.title}</p>}
                    </InputWrapper>
                    <InputWrapper className="NewPostBottom">
                        <Label>Choose your service*</Label>
                        <CheckboxWrapper>
                            <CheckboxDiv><InputCheckbox type="checkbox" name="delivery" value={values.delivery} checked={delIsChecked} onChange={delCheckHandler} required="required" /> <LabelCheckbox>Delivery</LabelCheckbox></CheckboxDiv>
                            <CheckboxDiv><InputCheckbox type="checkbox" name="pickup" value={values.pickup} checked={picIsChecked} onChange={picCheckHandler} required="required" /> <LabelCheckbox>Pick-up</LabelCheckbox></CheckboxDiv>
                        </CheckboxWrapper>
                        {errors.delivery && <p className="Errorstyle">{errors.delivery}</p>}
                    </InputWrapper>
                    <InputWrapper>
                        <Label>Price*</Label>
                        <InputCurrency type="number" name="price" maxLength="6" autoComplete="new-password" value={values.price} onChange={handleChange} required="required" />
                        <input type="hidden" name="jpy" value={values.jpy} />
                        {errors.price && <p className="Errorstyle">{errors.price}</p>}
                    </InputWrapper>
                    {
                        !delIsChecked ? (
                            <></>
                        ) : (
                            <InputWrapper>
                                <Label>Delivery price*</Label>
                                <InputCurrency type="number" name="deliveryprice" maxLength="6" disabled={disabled} autoComplete="new-password" value={values.deliveryprice} onChange={handleChange} required="required" />
                                {errors.deliveryprice && <p className="Errorstyle">{errors.deliveryprice}</p>}
                            </InputWrapper>)
                    }
                    <ServiceCost>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="infocost">
                                        <FaInfo />
                                        <span className="tooltiptext">The service cost of Bakesly is 15%. When more products are bought this percentage will be deducted from the total cost at customer checkout.</span>
                                    </span>
                                </td>
                                <td>Service cost indication of 1 product:</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span>{values.cost}</span></td>
                            </tr>
                        </tbody>
                    </ServiceCost>
                    <InputWrapper>
                        <Label>Product ready within*</Label>
                        <SelectInput name="time" autoComplete="new-password" value={values.time} onChange={handleChange} required="required">
                            <option defaultValue>Select a time</option>
                            <option value="1 hour">1 hour</option>
                            <option value="2 hours">2 hours</option>
                            <option value="3 hours">3 hours</option>
                            <option value="4 hours">4 hours</option>
                            <option value="24 hours">24 hours</option>
                            <option value="48 hours">48 hours</option>
                            <option value="72 hours">72 hours</option>
                            <option value="1 week">1 week</option>
                        </SelectInput>
                        {errors.time && <p className="Errorstyle">{errors.time}</p>}
                    </InputWrapper>
                </InputSectionWrapper>
                <InputSectionWrapper>
                    <InputWrapper>
                        <Label>You will contact the buyer within*</Label>
                        <SelectInput name="contact" autoComplete="new-password" value={values.contact} onChange={handleChange} required="required">
                            <option defaultValue>Select when</option>
                            <option value="1 hour" >1 hour</option>
                            <option value="2 hours">2 hours</option>
                            <option value="3 hours">3 hours</option>
                        </SelectInput>
                        {errors.contact && <p className="Errorstyle">{errors.contact}</p>}
                    </InputWrapper>
                    <InputWrapper>
                        <InputTags>
                            <Autocomplete
                                multiple
                                limitTags={1}
                                id="size-small-standard-multi"
                                size="small"
                                options={tagsArray}
                                onChange={(event, value) => {
                                    setSelectedTags(value)
                                    if (value.length > 4) {
                                        setDisable(true);
                                    } else if (value[0] === undefined || value[1] === undefined || value[2] === undefined || value[3] === undefined || value[4] === undefined) {
                                        setDisable(false);
                                    }
                                }}
                                value={selectedtagsArray}
                                // getOptionSelected={(option, value) => option.tags === value.tags}
                                isOptionEqualToValue={(option, value) => option.tags === value.tags}
                                getOptionLabel={(option) => option.tags}
                                disablePortal={true}
                                getOptionDisabled={(option) => {
                                    if (selectedtagsArray.length === 5) {
                                        return isDisable
                                    }
                                }
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" label="Tags (max. 5)*" placeholder="Browse tags" />
                                )}
                            />
                            {errors.tags && <p className="Errorstyle">{errors.tags}</p>}
                        </InputTags>
                    </InputWrapper>
                    <InputWrapper>
                        <Label>Ingredients (include allergies)*</Label>
                        <Textarea name="ingredients" maxLength="170" autoComplete="new-password" value={values.ingredients} onChange={handleChange} required="required" />
                        {errors.ingredients && <p className="Errorstyle">{errors.ingredients}</p>}
                    </InputWrapper>
                    <InputWrapper>
                        <Label>Description*</Label>
                        <Textarea name="description" maxLength="255" autoComplete="new-password" value={values.description} onChange={handleChange} required="required" />
                        {errors.description && <p className="Errorstyle">{errors.description}</p>}
                    </InputWrapper>
                </InputSectionWrapper>
                <InputSectionWrapper className="NewPostMobile">
                    <InputWrapper>
                        <ImageContainer>
                            {selectedImages.length > 0 &&
                                selectedImages.map((image, index) => {
                                    return (
                                        <div key={image + index} className="image">
                                            <img src={image} id="UploadedImages" alt="product" />
                                            <button
                                                id="DeleteImages"
                                                // onClick={() =>
                                                //     setSelectedImages(selectedImages.filter((e) => e !== image))
                                                // }
                                                onClick={deleteImage(image, index)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    );
                                })}
                        </ImageContainer>
                        <p className="ImagesSize">Max. 5 images*</p>
                        <button className="UploadProductImage" onClick={(e) => { e.preventDefault(); fileInputRef.current.click(); }} >Upload image</button>
                        <input id="Images" name="images" type="file" multiple ref={fileInputRef} accept="image/png,image/jpeg" onChange={handleFileChange} onClick={onInputClick} required="required" />
                        {errors.images && <p className="Errorstyleimagepost">{errors.images}</p>}
                    </InputWrapper>
                    <MinOrder>
                        Max. products in stock*<OrderQuantity type="number" name="quantity" maxLength="5" placeholder="0" min="1" autoComplete="new-password" value={values.quantity} onChange={handleChange} required="required"></OrderQuantity>
                        {errors.quantity && <p className="Errorstyle">{errors.quantity}</p>}
                    </MinOrder>
                    <EditPostButtons>
                        {
                            !isFailed ? (
                                <></>
                            ) : (
                                <PostFailed>Edit failed, click button again or refresh the page.</PostFailed>
                            )
                        }
                        {
                            !isLoading ? (
                                <>
                                    <motion.button className="DeletePost" type="button" onClick={deletePost} whileTap={{ scale: 0.9 }}>Delete</motion.button>

                                    <motion.button className="NewPost" type="submit" whileTap={{ scale: 0.9 }}>Edit product</motion.button>
                                </>

                            ) : (
                                <img src={spinner} alt="Loading" width="48" height="48" />
                            )
                        }
                    </EditPostButtons>
                </InputSectionWrapper>
            </form>
        </EditPostContainer>
    )
}

export default EditPost