import React, {useState}  from 'react';
import MobileMainNav from '../components/MobileMainNav';
import MainNavigation from '../components/MainNavigation';
import Explore from '../components/Explore';

const ExplorePage = ({ setAuth }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
            {/* <MobileMainNav isOpen={isOpen} toggle={toggle} setAuth={setAuth} /> */}
            <MainNavigation toggle={toggle} setAuth={setAuth} />
            <Explore />
        </>
    )
}

export default ExplorePage