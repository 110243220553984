import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const ProductCardLink = styled(LinkRouter)`
    text-decoration: none;
    color: black;
    height: inherit;
    display: inherit;

    &:hover {
        color: black;
    }
`;

export const CheckOutProductContainer = styled.div`
    display: flex;
    margin-top: 10px;

    @media screen and (max-width: 480px) {
        display: inherit;
    }
`;

export const ProductImageWrapper = styled.div`
 
    @media screen and (max-width: 480px) {
        width: fit-content;
        float: left;
    }
`;

export const ProductContentWrapper = styled.div`
    @media screen and (max-width: 1024px) {
        width: 185px;
    }
    
    @media screen and (max-width: 768px) {
        width: 300px;
    }

    @media screen and (max-width: 480px) {
        width: 185px;
        float: left;
    }
`;

export const ProductTitle = styled.div`
    margin-left: 10px;
    font-size: 16px;
    width: 160px;
    overflow: hidden;
    height: 21px;
    text-overflow: ellipsis;

    @media screen and (max-width: 1024px) {
        width: 185px;
        margin-top: 2px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 4px;
    }
`;

export const ProductReviews = styled.div`
    margin-top: -10px;
`;

export const ProductPrice = styled.div`
    margin-left: 10px;
    font-size: 16px;
`;

export const AddProductWrapper = styled.div`


    @media screen and (max-width: 480px) {
        width: fit-content;
    }
`;

export const DeleteProduct = styled.div`
    color: red;
`;

export const ProductQuantity = styled.input`
    font-size: 17px;
    width: 80px;
    height: 45px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    user-select: none;
    margin-left: 15px;
    color: #757575;
    background: #f5f5f5;

    @media screen and (max-width: 480px) {
        margin-left: 0px;
        margin-top: 10px;
    }
`;

export const ProductImage = styled.img`
    object-fit: cover;
    width: 90px;
    height: 65px;
`;

export const Ready = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
`;

export const UpdateProduct = styled.div`
    color: black;
    font-size: 14px;
`;

export const Options = styled.div`
    display: flex;
    margin-left: 20px;
`;
