export default function validate(values) {
    let errors = {};

    if (values.empty === true) {
        errors.empty = 'Empty cart!';
    }

    if (!values.firstname) {
        errors.firstname = 'First name is required';
    }
    else if (!/^[A-Za-z]+$/.test(values.firstname)) {
        errors.firstname = 'Enter a valid first name';
    }
    else if (values.firstname.length < 3) {
        errors.firstname = 'First name is to short';
    }
    else if (values.firstname.length > 30) {
        errors.firstname = 'First name is to long';
    }

    if (!values.lastname) {
        errors.lastname = 'Last name is required';
    }
    else if (!/^[A-Za-z]+$/.test(values.lastname)) {
        errors.lastname = 'Enter a valid last name';
    }
    else if (values.lastname.length < 3) {
        errors.lastname = 'Last name is to short';
    }
    else if (values.lastname.length > 30) {
        errors.lastname = 'Last name is to long';
    }

    if (!values.email.trim()) {
        errors.email = 'Email address is required';
    }
    else if (!/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
        errors.email = 'Email address is not correct';
    }
    else if (values.email.length < 3) {
        errors.email = 'Email address is to short';
    }

    if (!values.phone.trim()) {
        errors.phone = 'Phone number is required';
    }
    else if (!/^[0-9]+$/.test(values.phone)) {
        errors.phone = 'Phone number is invalid';
    }
    else if (values.phone.length < 4) {
        errors.phone = 'Phone number is to short';
    }
    else if (values.phone.length > 20) {
        errors.phone = 'Phone number is to long';
    }

    if (!values.zipcode) {
        errors.zipcode = 'Zipcode is required';
    }
    else if (!/^[a-zA-Z0-9 ]+$/.test(values.zipcode)) {
        errors.zipcode = 'Zipcode is invalid';
    }
    else if (values.zipcode.length > 90) {
        errors.zipcode = 'Zipcode is to long';
    }

    if (values.note.length > 500) {
        errors.note = 'Note is to long';
    }

    if (!values.streetname) {
        errors.streetname = 'Street name is required';
    }
    else if (!/^[A-Za-z ]+( [A-Za-z ]+)*$/.test(values.streetname)) {
        errors.streetname = 'Enter a valid street name';
    }
    else if (values.streetname.length < 3) {
        errors.streetname = 'Street name is to short';
    }
    else if (values.streetname.length > 90) {
        errors.streetname = 'Street name is to long';
    }

    if (!values.streetnumber.trim()) {
        errors.streetnumber = 'Street number is required';
    }
    else if (!/^[0-9a-zA-Z]+$/.test(values.streetnumber)) {
        errors.streetnumber = 'Street number is invalid';
    }
    else if (values.streetnumber.length > 7) {
        errors.streetnumber = 'Street number is to long';
    }

    if (!values.service) {
        errors.service = 'Option required';
    }

    if (values.stockcheck === 1) {
        errors.stockcheck = 'Not in stock anymore:';
    }

    if (values.closed === 1) {
        errors.closed = 'Shop is closed';
    }

    if (values.tos === false) {
        errors.tos = 'Checkbox is required';
    }

    if (values.service === "1" && values.minDeliv === 0) {
        errors.minDeliv = 'Need min. price to order for delivery';
    }

    if (!values.user) {
        errors.user = 'Shop is required';
    }

    if (!values.locval) {
        errors.locval = 'Loc is required';
    }

    return errors;
}