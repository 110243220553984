import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const useForm = (validate, productsArray) => {
  const [values, setValues] = useState({
    "firstname": "",
    "lastname": "",
    "email": "",
    "phone": "",
    "service": "",
    "zipcode": "",
    "streetname": "",
    "streetnumber": "",
    "note": "",
    "tos": "",
    "user": "",
    "shopid": "",
    "products": productsArray,
    "minDeliv": "",
    "stockcheck": 0,
    "closed": 0,
    "locval": localStorage.getItem('searchData')
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [stock, setStock] = useState([]);

  //   const [isFailed, setFailed] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    try {

      values.user = productsArray[0].name
      values.shopid = productsArray[0].user

      setErrors(validate(values));

      const baseURL =
        process.env.NODE_ENV === "production"
          ? "/api/"
          : "http://localhost:3001/api/";

      const tempOrder = async () => {
        await fetch(baseURL+'tempOrder', {
          credentials: 'same-origin',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.json()).then(async (result) => {
          if (result.status === false) {
            setOpen(false);
            values.closed = 1;
            setErrors(validate(values));
          } else if (result.stock !== undefined) {
            setOpen(false);
            values.stockcheck = 1;
            setErrors(validate(values));
            setStock(result.stock.join('\r\n'))
          } else if (result === "Fraud") {
            // console.log("here")
            setOpen(false)
          } else {
            setOpen(true);
            setClientSecret(result.client_secret);
          }
        });
      }

      const sendStripe = async () => {

        localStorage.setItem("shop", JSON.stringify(productsArray[0].user));

        await fetch(baseURL+"stripeconfig", {
          credentials: 'same-origin',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            user: productsArray[0].user
          },
        }).then(res => res.json()).then(async (result) => {
          setStripePromise(loadStripe(result.publishableKey, {
            stripeAccount: result.stripID
          }));
        });
      }

      if (Object.keys(validate(values)).length === 0) {
        // setOpen(true);
        tempOrder();
        sendStripe();
        setLoading(true);
      } else {
        setLoading(false);
        setOpen(false);
      }

      //   setTimeout(function(){
      //     setFailed(true);
      //     setLoading(false);
      //     }, 15000);
    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleSubmit, values, errors, isLoading, setLoading, open, setOpen, handleClose, clientSecret, setClientSecret, stripePromise, setStripePromise, stock, setStock };
};

export default useForm;