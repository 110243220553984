import React, { useState, useEffect } from 'react';
import {
    CheckOutProductContainer,
    ProductImageWrapper,
    ProductContentWrapper,
    ProductTitle,
    ProductReviews,
    ProductPrice,
    AddProductWrapper,
    DeleteProduct,
    ProductQuantity,
    ProductImage,
    Ready,
    ProductCardLink,
    UpdateProduct,
    Options
} from './CheckOutProductElements';
import "../animationStyle.css";
import UserData from '../../API/UserFinder';
import currencyArray from '../Currency/currency.json';
import useForm from './useForm';
import { RiDeleteBinLine } from 'react-icons/ri';
import { motion } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CheckOutProduct = ({ product }) => {

    const [productData, setProductData] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        
        const fetchProductData = async () => {
            try {
                const getProduct = await UserData.get('/cart', {
                    headers: {
                        product: product.product
                    }
                }).then(data => {
                    // if (data.data == "not") {
                        const productKey = Object.keys(sessionStorage);
                        const keyArray = [];
                        const keyID = [];
                        keyArray.push(productKey)
                        keyArray[0].map(product => {
                             keyID.push({name: product, data: JSON.parse(sessionStorage.getItem(product))}); 
                        })
                        keyID.map(id => {
                            if (data.data == id.data.product) {
                                sessionStorage.removeItem(id.name);
                                window.location.reload();
                                // console.log("delete")
                            } 
                        })

                        isMounted && setProductData(data.data.product);
                    // } else {
                    //     isMounted && setProductData(data.data.product);
                    // }
                  }).catch(error => console.error('Error:', error));
                } catch (err) {
                console.error(err.message);
            }
        };

        fetchProductData();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, []);

    const { handleChange, values, handleSubmit, deleteProduct } = useForm(
        product,
        productData
    );

    return (
        productData[0] !== undefined ? (
            <CheckOutProductContainer>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <ProductCardLink to={"/" + productData[0].username}>
                    <ProductImageWrapper>
                        <ProductImage src={productData[0].images} alt="Product picture" />
                    </ProductImageWrapper>
                    <ProductContentWrapper>
                        <ProductTitle>
                            {productData[0].producttitle}
                        </ProductTitle>
                        <ProductReviews>
                            <Ready>Ready within: {productData[0].productready}</Ready>
                        </ProductReviews>
                        <ProductPrice>
                            {currencyArray[productData[0].currency][0]}{productData[0].productprice}
                        </ProductPrice>
                    </ProductContentWrapper>
                </ProductCardLink>
                <AddProductWrapper>
                    <ProductQuantity type="number" name="quantity" value={values.quantity} onChange={handleChange} min="1"></ProductQuantity>
                    <Options><motion.button className='CheckoutOptions' whileTap={{ scale: 0.9 }} onClick={handleSubmit}><UpdateProduct>Update</UpdateProduct></motion.button>&nbsp;<motion.button className='CheckoutOptions' whileTap={{ scale: 0.9 }} onClick={deleteProduct}><DeleteProduct><RiDeleteBinLine size={15} /></DeleteProduct></motion.button></Options>
                </AddProductWrapper>
            </CheckOutProductContainer>
        ) : (
            <></>
        )
    )
}

export default CheckOutProduct
