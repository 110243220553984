import { useState, useContext } from 'react';
import { geoLocation, geoPlace } from "../geocoder/index";

const useSearch = () => {
    const [values, setValues] = useState({
        userLocation: '',
        userPlace: ''
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e)
        }
      }

    const handleSubmit = e => {
        e.preventDefault();

        try {
            if (geoLocation !== undefined && geoLocation !== "") {
                localStorage.clear();
                sessionStorage.clear();
                values.userLocation = geoLocation;
                values.userPlace = geoPlace;
                localStorage.setItem('searchData', JSON.stringify(values.userLocation));
                localStorage.setItem('searchInput', JSON.stringify(values.userPlace));

            } else {
                values.userLocation = "";
                values.userPlace  = "";
            }

            const search = localStorage.getItem('searchData');

            if (values.userLocation !== "" && values.userLocation !== search) {
                window.location.href = "/";
            }
// if(... is not undefined then redirect in explore and checkout only if this is known)

        } catch (err) {
            console.error(err.message);
        }
    }

    return { handleChange, handleSubmit, _handleKeyDown, values };
}

export default useSearch;

