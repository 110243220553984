import { useState } from 'react';
import { toast } from 'react-toastify';

const useForm = (product, productData) => {
  const [values, setValues] = useState({
    quantity: product.quantity,
  });

  const notAvail = () => toast.error("Order amount not available!");

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const deleteProduct = e => {
    e.preventDefault();

    try {
      if (productData[0] !== undefined) {
        sessionStorage.removeItem(productData[0].producttitle);
        window.location.reload(false)
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    try {
      if (productData[0] !== undefined) {
        if (Number(values.quantity) <= Number(productData[0].instock)) {
          sessionStorage.setItem(productData[0].producttitle, JSON.stringify({ product: product.product, user: product.user, name: product.name, quantity: values.quantity, delivery: product.delivery, pickup: product.pickup }));
          window.location.reload(false)
        } else {
          notAvail()
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  return { handleChange, values, handleSubmit, deleteProduct };
};

export default useForm;