import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  Order,
  OrderListWrapper,
  CheckOutListTitle,
  CheckOutLine,
  TotalCostWrapper,
  TransactionCost,
  DeliveryCost,
  TotalCost
} from './CompleteResElements';
import UserData from '../../API/UserFinder';
import OrderProducts from "../OrderProducts";

const CompleteRes = () => {
  const stripe = useStripe();

  const [message, setMessage] = useState(null);
  const [failed, setFailed] = useState(true);
  const [recieptData, setRecieptData] = useState([]);

  var dataArray;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(async ({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded check your email!");
          const getReciept = await UserData.get('/reciept', {
            headers: {
              data: JSON.stringify(paymentIntent.id)
            }
          });
          setRecieptData(getReciept.data.data)
          setFailed(false)
          break;
        case "requires_payment_method":
          setMessage("Order failed your payment was not successful, please try again.");
          setFailed(true)
          break;
        default:
          setMessage("Failed, something went wrong.");
          setFailed(true)
          break;
      }
    });
  }, [stripe]);

  if (recieptData.product !== undefined) {
    dataArray = JSON.parse(recieptData.product.productnames);
  }

  const setProducts = () => {
      return dataArray.map(product => <OrderProducts key={product} product={product} info={recieptData.pricetag} />)
  }

  return (
    <Order>
      {message}
      {!failed ? (
        <OrderListWrapper>
          <CheckOutListTitle>Order &#35;{recieptData.product.orderid} Summary:</CheckOutListTitle>
          {setProducts()}
          <br />
          <CheckOutLine />
          <TotalCostWrapper>
            <tbody>
              <tr>
                <td><TotalCost>{recieptData.shop.username}<span></span></TotalCost></td>
              </tr>
              <tr>
                <td><TotalCost>Service ready in max: <b>{recieptData.product.ready}</b><span></span></TotalCost></td>
              </tr>
              <tr>
                <td><TotalCost>See email for shop contact details<span></span></TotalCost></td>
              </tr>
            </tbody>
          </TotalCostWrapper>
        </OrderListWrapper>
      ) : (
        <></>
      )}
    </Order>
  )
}

export default CompleteRes