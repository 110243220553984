import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {
    ProductContainer,
    ProductTitle,
    ProductMadeBy,
    ProductDescriptionLabel,
    ProductDescription,
    ProductIngredients,
    ProductPriceTag,
    ProductDeliveryCost,
    ProductAvailable,
    ProductBuyButtonWrapper,
    ProductQuantity,
    ProductTags,
    ProductDisclaimer,
    ProductContentsWrapper,
    ProductTitleWrapper,
    ProductDescriptionWrapper,
    ProductBuyingWrapper,
    ProductImage,
    ProductScrollWrapper,
    NA,
} from './ProductViewElements';
import { motion } from "framer-motion";
import "../animationStyle.css";
import Carousel from 'react-bootstrap/Carousel';
import UserData from '../../API/UserFinder';
import currencyArray from '../Currency/currency.json';
import AddCart from './AddCart';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// when a users location isnt known send to location pageXOffset, if user loaction is known check if baker is wihtin delivery range if not then its not possible 


const ProductView = ({ product, available, closed }) => {

    const imagesArray = product.images;
    const [tags, setTags] = useState([]);

    const { username } = useParams();

    const curr = currencyArray[product.currency][0];

    useEffect(() => {

        const fetchTags = async () => {
            try {
                const getTags = await UserData.get('/producttags', {
                    headers: {
                        data: product.product_tags
                    }
                });
                setTags(getTags.data.data.tag)
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchTags();

    }, []);

    const tagsArray = [];

    tags.map(tag => {
        tagsArray.push(tag.tags)
    })

    const checkService = JSON.parse(localStorage.getItem(username));
    const checkServiceArray = [];
    checkServiceArray.push(checkService)

    const search = localStorage.getItem('searchData');

    const setAvailable = () => {
        if (search === null) {
            return <NA>Enter postcode to see availability</NA>
        } else if (available === false) {
            return <NA>Not available in your area</NA>
        }else if (checkServiceArray[0] === null) {
            window.location.replace("/explore");
        } else if (checkServiceArray[0].delivery === false && product.delivery === true && product.pickup === false) {
            return <NA>Delivery or pickup not available</NA>
        } else {
            return <ProductBuyButtonWrapper onSubmit={handleSubmit}>
                <motion.button className="ProductBuyButton" whileTap={{ scale: 0.9 }} type="submit" >Buy</motion.button>
                <ProductQuantity type="number" name="quantity" placeholder="1" min="1" value={values.quantity} onChange={handleChange}></ProductQuantity> <span className="AmountLeft">{product.instock} available</span>
            </ProductBuyButtonWrapper>
        }
    }

    var service;
    var delivery = "";


    if (checkServiceArray[0] !== null) {
        if (product.delivery === true && product.pickup === true && checkServiceArray[0].delivery === true) {
            service = "Delivery or free pick up";
        } else if (product.delivery === true && checkServiceArray[0].delivery === true) {
            service = "Only delivery";
        } else if (product.pickup === true) {
            service = "Only pick up";
        }

        if (product.deliveryprice >= 0 & product.delivery === true && checkServiceArray[0].delivery === true) {
            delivery = "+" + curr + " " + product.deliveryprice;
        } else {
            delivery = ""
        }
    } else {
        if (product.delivery === true && product.pickup === true) {
            service = "Delivery or free pick up";
        } else if (product.delivery === true) {
            service = "Only delivery";
        } else if (product.pickup === true) {
            service = "Only pick up";
        }

        if (product.deliveryprice >= 0 & product.delivery === true) {
            delivery = "+" + curr + " " + product.deliveryprice;
        } else {
            delivery = ""
        }
    }

    const { handleChange, handleSubmit, values, handleReset } = AddCart({
        product,
        service,
        closed
    });

    return (
        <ProductContainer>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Carousel className="ProductImagesWrapper">
                {
                    imagesArray.map((image, index) =>
                        <Carousel.Item key={image + index}>
                            <ProductImage src={image} alt="product" />
                        </Carousel.Item >
                    )
                }
            </Carousel>
            <ProductScrollWrapper>
                <ProductContentsWrapper>
                    <ProductTitleWrapper>
                        <ProductTitle>{product.producttitle}</ProductTitle>
                        <ProductMadeBy>by {username}</ProductMadeBy>
                    </ProductTitleWrapper>
                    <ProductBuyingWrapper>
                        <ProductPriceTag>Price: {curr} {product.productprice}</ProductPriceTag>
                        <ProductDeliveryCost>{delivery} {service}</ProductDeliveryCost>
                        <ProductAvailable>Ready within: {product.productready}</ProductAvailable>
                        {setAvailable()}
                        <ProductDisclaimer><b>Seller will contact you within {product.contacttime}</b></ProductDisclaimer>
                    </ProductBuyingWrapper>
                    <ProductIngredients><b>Ingredients:</b> {product.ingredients}</ProductIngredients>
                    <ProductDescriptionWrapper>
                        <ProductDescriptionLabel>Description:</ProductDescriptionLabel>
                        <ProductDescription>{product.description}</ProductDescription>
                        <ProductTags>Tags: {tagsArray.join(', ')}</ProductTags>
                    </ProductDescriptionWrapper>
                </ProductContentsWrapper>
            </ProductScrollWrapper>
        </ProductContainer>
    );
}
export default ProductView;
