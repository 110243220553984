import { useContext, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { UsersContext } from '../../context/UsersContext';

const useForm = (validate, product) => {
  const [values, setValues] = useState({
    title: product.producttitle,
    price: product.productprice,
    deliveryprice: product.deliveryprice,
    ingredients: product.ingredients,
    time: product.productready,
    tags: product.product_tags,
    contact: product.contacttime,
    description: product.description,
    delivery: '',
    pickup: '',
    images: '',
    quantity: product.instock,
    cost: '',
    username: '',
    productid: product.productid,
    jpy: ''
  });
  const [selectedImages, setSelectedImages] = useState(product.images);
  const [imageValidate, setImageValidate] = useState(product.images);
  const [isLoading, setLoading] = useState(false);
  const [isPosted, setPosted] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [maxPosts, setMaxPosts] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [errors, setErrors] = useState({});
  const axiosAuth = useAuth();
  const { userAuth } = useContext(UsersContext);

  values.username = userAuth.username;

  var postDelete;

  if (!userAuth.user) {
    postDelete = userAuth.id;
  } else if (!userAuth.id) {
    postDelete = userAuth.user;
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleFileChange = e => {
    const reader = new FileReader();
    const file = e.target.files[0];

    setImageValidate([
      ...imageValidate,
      file
    ]);

    if (imageValidate.length < 5) {
      reader.onloadend = () => {
        baseImage(reader.result)
      };
    // } else if (file.size > 1500000) {
    //   alert("Selected image file was to big, max. 1.5MB");
    //   setImageValidate(imageValidate.filter((_, i) => file !== i));
    } else if (imageValidate.length > 4) {
      alert("Only 5 images allowed");
      setImageValidate(imageValidate.filter((_, i) => file !== i));
    }
  
    reader.readAsDataURL(file);

    const baseImage = (encodedImage) => {
        setSelectedImages([
          ...selectedImages,
          encodedImage,
        ]);
    }
  }

  const deletePost = async () => {
    setLoading(true);
    await axiosAuth.post('/deletepost', { 
        data: product.productid,
        id: postDelete
      }).then(data => {
        if (data.data === "Deleted"){
          window.location.reload();
        } 
      }).catch(error => {
        console.error('Error:', error)
        setFailed(true);
        setLoading(false);
      });
  }

  values.tags = selectedTags;

  values.images = selectedImages;

  const handleSubmit = e => {
    e.preventDefault();

    try {

      setErrors(validate(values, imageValidate));

      const sendResponse = async () => {

        await axiosAuth.post('/updatepost', values).then(data => {
          console.log(data)
          if (data.data === "Same post title") {
            values.title = "Try again";
            setErrors(validate(values, imageValidate));
            setLoading(false);
            setFailed(false);
          } else if (data.data === "Success"){
            setPosted(true);
            window.location.reload();
          } else if (data.data === "Too many posts") {
            setMaxPosts(true)
          } else {
            setPosted(false);
            setLoading(false);
          }
        }).catch(error => {
          console.error('Error:', error);   
          setFailed(true);
          setLoading(false);
        }); 

      }

      if (Object.keys(validate(values, imageValidate)).length === 0) {
          sendResponse();
          setLoading(true);

          // setTimeout(function(){
          //   setFailed(true);
          //   setLoading(false);
          //   }, 15000);
      } else {
        setLoading(false);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleFileChange, handleSubmit, values, imageValidate, setImageValidate, selectedImages, setSelectedImages, isLoading, setLoading, isPosted, setPosted, maxPosts, setMaxPosts, errors, deletePost, selectedTags, setSelectedTags, isFailed, setFailed };
}

export default useForm;