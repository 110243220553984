import React, { useState, useEffect } from 'react';
import {
    BakerCardLink,
    BakerCardContainer,
    BakerCardContents,
    BakerCardImageWrapper,
    BakerCardImage,
    BakerNamer,
    BakerReview,
    BakerBio,
    UserRatings,
    BakerTime,
    MinOrder,
    CardLine,
    DisplayName,
    UserVerified,
    Closed, 
    Open
} from './BakerCardElements';
// import Rating from '@material-ui/lab/Rating';
import Rating from '@mui/material/Rating';
import "../animationStyle.css";
import { FaCheckCircle } from "react-icons/fa";
import UserData from '../../API/UserFinder';
import currencyArray from '../Currency/currency.json';
import base from '../../images/bakeslycard.png';
 
const BakerCard = ({ shops, delivery, open }) => {

    const [shopData, setShopData] = useState([]);
    const [service, setService] = useState("Only pick up");
    const [ifDelivery, setDelivery] = useState("");
    const [image, setImage] = useState("");
    const [deliveryData, setDeliveryData] = useState(false);
    const [pickupData, setPickupData] = useState(false);
    const [review, setReview] = useState([]);
    const [amountReviews, setAmountReviews] = useState([]);
    // const [avgReview, setAvgReview] = useState(0);
    const [values, setValues] = useState({
        avgReview: 0
      });

    const setTime = () => {
        if (open === 0) {
            return <Closed>Closed</Closed>
        } else {
            return <Open>Duration: {shops.mintime} - {shops.maxtime}</Open>
        }
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchShopData = async () => {
            try {
                const getShopData = await UserData.get('/products', {
                    headers: {
                        user: shops.username
                    }
                });

                setShopData(getShopData.data.data.product);

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchShopData();

        const curr = currencyArray[shops.currency][0];

        delivery.map(user => {
            if (user.username === shops.username) {
                if (user.delivery === true && user.pickup === true) {
                    setService("Delivery and free pick up");
                    setDeliveryData(true)
                    setPickupData(true)
                } else if (user.delivery === true) {
                    setService("Only delivery");
                    setDeliveryData(true);
                } else if (user.pickup === true) {
                    setService("Only pick up");
                    setPickupData(true)
                }

                if (user.mindeliveryprice > 0) {
                    setDelivery("Min. " + curr + " " + user.mindeliveryprice + " | ")
                } else {
                    setDelivery("")
                }
            }
        })

        if (shops.pickup === true) {
            setPickupData(true)
        }

        const fetchReviews = async () => {
            try {
                const getReview = await UserData.get('/getReview', {
                    headers: {
                        user: shops.username
                    }
                });

               isMounted && setReview(getReview.data);

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchReviews();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, []);

    // localStorage.setItem(shops.username, JSON.stringify(ifDelivery + service));
    localStorage.setItem(shops.username, JSON.stringify({delivery: deliveryData, pickup: pickupData})); 

    useEffect(() => {

        if(shopData[0] !== undefined) {
            setImage(shopData.at(0).images.at(0))
        } else {
            setImage(base)
        }

        // if (reviews > 1000) {
        //     "999+"
        // }

    }, [shopData]);

    const avgReviewArray = [];

    useEffect(() => {

        if (review.length > 0) {
            review.map(data => {
                avgReviewArray.push(Number(data.rating))
            })

            if (avgReviewArray.length > 0) {
                const average = array => array.reduce((a, b) => a + b) / array.length;
                const checkAvg = average(avgReviewArray).toFixed(1);

                if (Number(checkAvg) > 5) {
                    values.avgReview = 0;
                    // setAvgReview(Number(0))
                } else {
                    values.avgReview = Number(checkAvg);
                    // setAvgReview(Number(checkAvg))
                }
            }
    
            setAmountReviews(avgReviewArray.length);
        } else {
            setAmountReviews(0);
            // setAvgReview(0)
            values.avgReview = 0;
        }

    }, [review]);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
      };

    return (
        <BakerCardContainer>
            <BakerCardLink to={"/" + shops.username}>
                <DisplayName>
                    <BakerNamer>{shops.username}</BakerNamer>
                    <BakerTime>{setTime()}</BakerTime>
                    {!shops.verified ? (
                        <></>
                    ) : (
                        <UserVerified><FaCheckCircle color="#f0929f" /></UserVerified>
                    )
                    }
                </DisplayName>
                <BakerCardImageWrapper>
                    <BakerCardImage src={image} alt="BakerCard" />
                </BakerCardImageWrapper>
                <BakerCardContents>
                    <MinOrder>{ifDelivery} {service}</MinOrder>
                    <CardLine></CardLine>
                    <BakerReview><Rating className="BakerRating" name="read-only" value={values.avgReview} onChange={handleChange} size="small" precision={0.5} readOnly /> <UserRatings><u>{values.avgReview}/5</u> | {amountReviews}</UserRatings></BakerReview>
                    <BakerBio>{shops.bio}</BakerBio>
                </BakerCardContents>
            </BakerCardLink>
        </BakerCardContainer>
    )
}

export default BakerCard
