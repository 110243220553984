import { useContext, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { UsersContext } from '../../context/UsersContext';

const useForm = (validate) => {
  const [values, setValues] = useState({
    title: '',
    price: '',
    deliveryprice: '',
    ingredients: '',
    time: '',
    tags: '',
    contact: '',
    description: '',
    delivery: '',
    pickup: '',
    images: '',
    quantity: '',
    cost: '',
    username: '',
    jpy: ''
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageValidate, setImageValidate] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isPosted, setPosted] = useState(false);
  const [maxPosts, setMaxPosts] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const axiosAuth = useAuth();
  const { userAuth } = useContext(UsersContext);

  values.username = userAuth.username;

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleFileChange = e => {
    const reader = new FileReader();
    const file = e.target.files[0];

    setImageValidate([
      ...imageValidate,
      file
    ]);

    if (imageValidate.length < 5) {
      reader.onloadend = () => {
        baseImage(reader.result)
      };
    // } else if (file.size > 1500000) {
      // alert("Selected image file was to big, max. 1.5MB");
      // setImageValidate(imageValidate.filter((_, i) => file !== i));
    } else if (imageValidate.length > 4) {
      alert("Only 5 images allowed");
      setImageValidate(imageValidate.filter((_, i) => file !== i));
    }
  
    reader.readAsDataURL(file);

    const baseImage = (encodedImage) => {
        setSelectedImages([
          ...selectedImages,
          encodedImage,
        ]);
    }
  }

  values.tags = selectedTags;

  values.images = selectedImages;

  const handleSubmit = e => {
    e.preventDefault();

    try {

      setErrors(validate(values, imageValidate));

      const sendResponse = async () => {

        await axiosAuth.post('/newpost', values).then(data => {
          if (data.data === "Same post title") {
            values.title = "Try again";
            setErrors(validate(values, imageValidate));
            setLoading(false);
            setFailed(false);
          } else if (data.data === "Success"){
            setPosted(true);
            window.location.reload();
          } else if (data.data === "Too many posts") {
            setMaxPosts(true)
          } else {
            setPosted(false);
            setLoading(false);
          }
        }).catch(error => {
          console.error('Error:', error);   
          setFailed(true);
          setLoading(false);
        }); 

      }

      if (Object.keys(validate(values, imageValidate)).length === 0) {
          sendResponse();
          setLoading(true);

            // setTimeout(function(){
            //   console.log("here2")
            //   setFailed(true);
            //   setLoading(false);
            //   }, 5000);
      } else {
        setLoading(false);
        // setFailed(true);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleFileChange, handleSubmit, values, imageValidate, setImageValidate, selectedImages, setSelectedImages, isLoading, setLoading, isPosted, setPosted, maxPosts, setMaxPosts, isFailed, setFailed, selectedTags, setSelectedTags, errors };
}

export default useForm;