import React, {useState} from 'react'
import MobileMainNav from '../components/MobileMainNav';
import MainNavigation from '../components/MainNavigation';
import Order from '../components/Order';

const OrderPage = ({ setAuth }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
            {/* <MobileMainNav isOpen={isOpen} toggle={toggle} setAuth={setAuth} /> */}
            <MainNavigation toggle={toggle} setAuth={setAuth} />
            <Order />
        </>
    )
}

export default OrderPage
