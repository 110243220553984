import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const UserContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

export const UserCardWrapper = styled.div`
    margin: auto;
    width: 1080px;
    padding-bottom: 20px;
    margin-top: 48px;

    @media screen and (max-width: 1024px) {
        width: 900px;
    }

    @media screen and (max-width: 820px) {
        width: 600px;
    }

    @media screen and (max-width: 768px) {
        width: 730px;
        margin-top: 35px;
    }

    @media screen and (max-width: 480px) {
        width: 360px;
        margin-top: 30px;
    }

    @media screen and (max-width: 375px) {
        width: 340px;
        margin-top: 20px;
    }

    @media screen and (max-width: 340px) {
        width: 300px;
    }
`;

export const UserRatings = styled.div`
    margin-top: 10px;
    margin-left: 5px;
    font-size: 12px;

    @media screen and (max-width: 1024px) {
        margin-top: 0px;
    }

    @media (max-width:480px)  {
        margin-top: 8px;
    }
`;

export const UserImageWrapper = styled.div`
    width: 163px;
    height: 163px;
    border-radius: 50%;
    margin: auto;
    background: white;

    @media screen and (max-width: 768px) {
        width: 151px;
        height: 151px;
    }
`;

export const UserImage = styled.img`
    object-fit: cover;
    width: 163px;
    height: 163px;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
        width: 151px;
        height: 151px;
    }
`;

export const UserProfileWrapper = styled.div`
    width: 70%;
    height: fit-content;
    display: inline-block;
    margin-top: 25px;
    margin-left: 30px;

    @media screen and (max-width: 1024px) {
        width: 670px;
    }

    @media screen and (max-width: 820px) {
        width: 500px;
    }

    @media screen and (max-width: 768px) {
        width: 525px;
        margin-left: 20px;
        margin-top: 30px;
    }

    @media (max-width:480px)  {
        width: 100%;
        padding: 20px;
        margin: auto;
    }
`;

export const UserName = styled.div`
    font-size: 25px;
    float: left;

    @media screen and (max-width: 768px) {
        font-size: 22px;
    }

    @media screen and (max-width: 480px) {
        line-break: anywhere;
        font-size: 18px;
    }
`;

export const UserRatingsWrapper = styled.div`
    display: flex;
    width: 170px;
    cursor: pointer;
`;

export const UserBio = styled.div`
    font-size: 18px;
    width: 555px;
    height: 81px;
    color: #757575;
    margin-bottom: 5px;
    overflow-wrap: break-word;

    @media screen and (max-width: 820px) {
        width: 500px;
    }

    @media screen and (max-width: 768px) {
        width: 440px;
        font-size: 15px;
        height: 75px;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        font-size: 13px;
        height: 81px;
    }
`;

export const UserPostcode = styled.div`
    font-size: 12px;
    white-space: nowrap;
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1024px) {
        width: 555px;
    }

    @media screen and (max-width: 820px) {
        width: 500px;
    }

    @media screen and (max-width: 768px) {
        width: 440px;
    }

    @media screen and (max-width: 480px) {
        font-size: 10px;
        line-break: anywhere;
        width: 100%;
    }
`;

export const UserButtonsWrapper = styled.div`
    float: right;
    display: flex;
    padding-right: 20px;
    margin-bottom: 15px;

    @media (max-width:480px)  {
        margin-top: 15px;
        padding-right: 0;
    }

    @media (max-width:340px)  {
        display: block;
    }

    @media (max-width:320px)  {
        width: 220px;
    }
`;

export const UserAvailableSlider = styled.div`
    font-size: 14px;
    margin: auto;
    margin-top: 7px;
    margin-right: 20px;

    @media screen and (max-width: 768px) {
        margin-top: 3px;
        margin-right: 15px;
    }

    @media screen and (max-width: 340px) {
        margin-bottom: 20px;
        margin-left: 45px;
    }
`;

export const UserNewPostButton = styled.div`
    margin-right: 15px;

    @media (max-width:480px)  {
        margin-top: 15px;
    }
`;

export const UserMyOrders = styled(LinkRouter)`
    margin-right: 15px;

    @media (max-width:480px)  {
        margin-top: 15px;
    }

    @media (max-width:340px)  {
        width: 85px;
        // margin-top: 0;
        float: left;
    }
`;

export const UserSettings = styled(LinkRouter)`
    margin: auto;

    @media (max-width:480px)  {
        margin-bottom: 5px;
    }

    @media (max-width:320px)  {
        float: right;
        margin-top: -30px;
        margin-bottom: 0;
    }
`;

export const UserImageContainer = styled.div`
    width: 30%;
    display: flex;
    background: linear-gradient(314deg,rgba(182,216,243,1) 4%,rgba(182,216,243,1) 10%,rgba(245,206,224,1) 100%);
    padding-bottom: 25px;
    padding-top: 25px;

    @media (max-width:480px)  {
        width: 100%;
    }
`;

export const UserWrapper = styled.div`
    width: 100%;
    display: flex;
    box-shadow: 0px 10px 10px 0px #0000000a;

    @media (max-width:480px)  {
        display: inherit;
        padding-bottom: 0px;
    }
`;

export const UserPicWrapper = styled.div`
    height: 194px;
    display: inline-flex;
    margin: auto;

    @media screen and (max-width: 768px) {
        height: 180px;
    }
`;

export const NameReviewWrapper = styled.div`
    margin-bottom: 10px;
    width: fit-content;

    @media screen and (max-width: 1024px) {
        width: 555px;
    }

    @media screen and (max-width: 820px) {
        width: 500px;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 6px;
        width: fit-content;
    }
`;

export const CloseProduct = styled.div`
    cursor: pointer;
    width: fit-content;
    position: absolute;
    right: 20px;
    top: 20px;
`;

export const MinOrder = styled.div`
    font-size: 12px;
    margin-top: 10px;

    @media screen and (max-width: 768px) {
        margin-top: 8px;
    }
`;

export const UserVerified = styled.div`
    font-size: 15px;
    right: 60px;
    top: 20px;
    position: absolute;

    @media screen and (max-width: 820px) {
        right: 25px;
        top: 0px;
    }

    @media screen and (max-width: 768px) {
        font-size: 13px;
        right: 30px;
        top: 35px;
        position: absolute;
    }

    @media (max-width:480px)  {
        color: #f0929f;
    }
`;

export const Closed = styled.div`
    color: red;
    font-weight: 500;
`;

export const SetupStripeContainer = styled.div`
    height: 500px;
    position: relative;
`;

export const StripeContent = styled.div`
    text-align: center;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const StripeTitle = styled.h4`
    margin-bottom: 15px;
`;

export const ReviewTitle = styled.div`
    font-size: 25px;
    background: linear-gradient(314deg, rgba(182,216,243,1) 4%, rgba(182,216,243,1) 10%, rgba(245,206,224,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: white;
    width: fit-content;
`;

export const ReviewPopupContainer = styled.div`

`;