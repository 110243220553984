import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const BackButtonWrapper = styled(LinkRouter)`
    width: fit-content;
    position: absolute;
    left: 25px;
    top: 25px;
    font-size: 18px;
    color: black !important;
`;

export const HelpMeButtonWrapper = styled.div`
    width: 120px;
    margin-bottom: 20px;
    margin-left: 20px;
`;

export const SettingsOptions = styled.div`
    display: flex;
    width: 100%;
    position: absolute;
`;

export const UserLegalOptions = styled.div`
    position: absolute;
    right: 10px;
    margin-left: 20px;
`;

export const SetupStripeContainer = styled.div`
    height: 500px;
    position: relative;
`;

export const StripeContent = styled.div`
    text-align: center;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const StripeTitle = styled.h4`
    margin-bottom: 15px;
`;

export const LinkReadMore = styled(LinkRouter)`
  
`;