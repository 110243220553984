import { useState } from 'react';

const useForm = (validate, token) => {
  const [values, setValues] = useState({
    password: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    try {

      setErrors(validate(values));

      const baseURL =
        process.env.NODE_ENV === "production"
            ? "/api/"
            : "http://localhost:3001/api/";

      const sendResponse = async () => {
        await fetch(baseURL+'resetpw/'+token, {
          credentials: 'same-origin',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(values)
        }).then(res => res.json()).then(data => {
            if (data.errors !== undefined && data.errors[0].msg === "Password must be greater than 8") {
              values.password = "";
              setErrors(validate(values));
            } else if (data === "Success") {
              window.location.replace("https://bakesly.com/signin");
            }
        }).catch(error => console.error('Error:', error));
      }

      if (Object.keys(validate(values)).length === 0) {
        sendResponse();
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  return { handleChange, handleSubmit, values, errors };
}

export default useForm;