export default function validate(values) {
  let errors = {};
  // let errorPasswordLogin = {};

  if (!values.emailaddress.trim()) {
    errors.emailaddress = 'Email address is required';
  } else if (!/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(values.emailaddress)) {
    errors.emailaddress = 'Email address is not known';
  }

  if (!values.password) {
    errors.password = 'Password is incorrect';
  } else if (values.password.length < 8) {
    errors.password = 'Password needs to be 8 characters or more';
  }

  return errors;
}