import React from 'react'
import ForgotPass from '../components/ForgotPass';

const ForgotPassPage = () => {
    return (
        <ForgotPass />
    )
}

export default ForgotPassPage
