import React, {useState}  from 'react';
import MobileMainNav from '../components/MobileMainNav';
import MainNavigation from '../components/MainNavigation';
import CheckEmail from '../components/CheckEmail';

const CheckEmailPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
            {/* <MobileMainNav isOpen={isOpen} toggle={toggle} />
            <MainNavigation toggle={toggle} /> */}
            <CheckEmail />
        </>
    )
}

export default CheckEmailPage