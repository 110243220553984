export default function validate(values) {
    let errors = {};
  
    if (!values.password) {
        errors.password = 'Password is required, must contain at least 1 special character, 1 numeric character and 1 uppercase character';
      } 
      else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/.test(values.password)) {
        errors.password = 'Password must contain at least 1 special character, 1 numeric character and 1 uppercase character';
      }
      else if (values.password.length < 8) {
        errors.password = 'Password needs to be 8 characters or more';
      }
  
    return errors;
  }