import React, {useState} from 'react'
import MobileMainNav from '../components/MobileMainNav';
import MainNavigation from '../components/MainNavigation';
import Building from '../components/Building';
import '../components/animationStyle.css';

const BuildingPage = ({ setAuth }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
            {/* <MobileMainNav isOpen={isOpen} toggle={toggle} setAuth={setAuth} /> */}
            <div className="hideOnUser">
                <MainNavigation toggle={toggle} setAuth={setAuth} />
            </div>
            <Building />
        </>
    )
}

export default BuildingPage
