import styled from 'styled-components';

export const ProductContainer = styled.div`
    // height: 100%;
    // width: 100%;
    // position: absolute;
    display: flex;

    @media screen and (max-width: 480px) {
        display: contents;
    }
`;

export const ProductTitle = styled.div`
    font-size: 24px;

    @media screen and (max-width: 768px) {
        font-size: 23px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const ProductMadeBy = styled.div`
    font-size: 16px;
    text-decoration: none;
    color: black;

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`;

export const ProductDescriptionLabel = styled.div`
    font-size: 16px;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        font-size: 13px;
    }
`;

export const ProductDescription = styled.div`
    font-size: 16px;
    width: 370px;
    overflow-wrap: break-word;

    @media screen and (max-width: 820px) {
        font-size: 13px;
        width: auto;
    }
`;

export const ProductIngredients = styled.div`
    font-size: 16px;
    margin-top: 20px;
    width: 370px;
    overflow-wrap: break-word;

    @media screen and (max-width: 820px) {
        font-size: 13px;
        width: auto;
    }
`;

export const ProductPriceTag = styled.div`
    font-size: 20px;

    @media screen and (max-width: 820px) {
        font-size: 17px;
    }
`;

export const ProductDeliveryCost = styled.div`
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;

    @media screen and (max-width: 820px) {
        font-size: 13px;
    }
`;

export const ProductAvailable = styled.div`
    font-size: 16px;

    @media screen and (max-width: 820px) {
        font-size: 14px;
    }
`;

export const ProductBuyWrapper = styled.div`
    
`;

export const ProductQuantity = styled.input`
    font-size: 17px;
    width: 80px;
    height: 45px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    user-select: none;
    margin-left: 15px;
    color: #757575;
    background: #f5f5f5;

    @media screen and (max-width: 820px) {
        width: 80px;
        height: 40px;
    }
`;

export const ProductTags = styled.div`
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 20px;

    @media screen and (max-width: 820px) {
        font-size: 13px;
        width: auto;
    }
`;

export const ProductDisclaimer = styled.div`
    font-size: 14px;
    margin-top: 15px;

    @media screen and (max-width: 820px) {
        font-size: 13px;
    }
`;

export const ProductBuyButtonWrapper = styled.form`

    @media screen and (max-width: 820px) {
        font-size: 13px;
    }
`;

export const ProductContentsWrapper = styled.div`
    margin-right: 30px;
    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 480px) {
        margin-bottom: 30px;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 20px;
    }
`;

export const ProductTitleWrapper = styled.div`
    width: 345px;
    word-break: break-all;

    @media screen and (max-width: 820px) {
        width: auto;
    }
`;

export const ProductDescriptionWrapper = styled.div`
    margin-top: 35px;

    @media screen and (max-width: 820px) {
        margin-top: 25px;
    }
`;

export const ProductBuyingWrapper = styled.div`
    margin-top: 30px;   

    @media screen and (max-width: 820px) {
        margin-top: 25px;
    }
`;

export const ProductImage = styled.img`
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
`;

export const ProductScrollWrapper = styled.div`
    width: 400px;
    height: 600px;
    float: right;
    overflow: scroll; 

    @media screen and (max-width: 1024px) {
        width: 395px;
        height: 500px;
    }

    @media screen and (max-width: 820px) {
        width: 320px;
        height: 450px;
    }

    @media screen and (max-width: 768px) {
        width: 300px;
        height: 400px;
    }

    @media screen and (max-width: 480px) {
        width: 370px;
        height: 325px;
        float: inherit;
    }

    @media screen and (max-width: 380px) {
        width: 350px;
        height: 305px;
    }

    @media screen and (max-width: 375px) {
        width: 330px;
    }

    @media screen and (max-width: 360px) {
        width: 330px;
        height: 280px;
    }

    @media screen and (max-width: 320px) {
        width: 275px;
        height: 200px;
    }
`;

export const NA = styled.div`
    color: red;
    font-weight: 500;;
    font-size: 14px;
`;