import React, {useState} from 'react'
import MobileMainNav from '../components/MobileMainNav';
import MainNavigation from '../components/MainNavigation';
import CheckOut from '../components/CheckOut';
import '../components/animationStyle.css';

const CheckOutPage = ({ setAuth }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
        <>
            {/* <MobileMainNav isOpen={isOpen} toggle={toggle} setAuth={setAuth} /> */}
            <div className="hideOnUser">
                <MainNavigation toggle={toggle} setAuth={setAuth} />
            </div>
            <CheckOut />
        </>
    )
}

export default CheckOutPage
