import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
    UserContainer,
    UserCardWrapper,
    UserImageWrapper,
    UserImage,
    UserProfileWrapper,
    UserName,
    UserBio,
    UserPostcode,
    UserButtonsWrapper,
    UserWrapper,
    UserPicWrapper,
    UserImageContainer,
    NameReviewWrapper,
    UserRatings,
    UserRatingsWrapper,
    UserAvailableSlider,
    UserNewPostButton,
    UserMyOrders,
    UserSettings,
    CloseProduct,
    MinOrder,
    UserVerified,
    Closed,
    SetupStripeContainer,
    StripeTitle,
    StripeContent,
    ReviewTitle,
    ReviewPopupContainer
} from './UserElements';
import { FaCheckCircle } from "react-icons/fa";
import { BiMap } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
// import Rating from '@material-ui/lab/Rating';
import Rating from '@mui/material/Rating';
import "../animationStyle.css";
import { motion } from "framer-motion";
import Switch from "react-switch";
import "../animationStyle.css";
import Popup from 'reactjs-popup';
import Review from '../Review';
import NewPost from '../NewPost';
import ProductCards from '../ProductCards';
import { UsersContext } from '../../context/UsersContext';
import UserData from '../../API/UserFinder';
import currencyArray from '../Currency/currency.json';
import * as turf from '@turf/turf'
import useAuth from '../../Hooks/useAuth';
import spinner from '../../images/Spinner-1s-200px.gif';

const User = () => {

    const [products, setProducts] = useState([]);
    const [shownProducts, setShownProducts] = useState([]);
    const [available, setAvailable] = useState(false);
    const [paymentSetup, setPayementSetup] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [review, setReview] = useState([]);
    const [amountReviews, setAmountReviews] = useState([]);
    // const [avgReview, setAvgReview] = useState(0);
    const [values, setValues] = useState({
        avgReview: 0
      });

    const axiosAuth = useAuth();

    const { username } = useParams();

    const { selectedUser, setSelectedUser, userAuth } = useContext(UsersContext);

    const userView = userAuth.username;

    const [shops, setShops] = useState([]);
    const [shopsLoc, setShopsLoc] = useState([]);

    const search = JSON.parse(localStorage.getItem('searchData'));

    if (search !== null) {
        const baseLon = Number(search.split(',')[0]);
        const baselat = Number(search.split(',')[1]);

        var buyerradius = 25;
        let buyerlon = baseLon, buyerlat = baselat
        var buyercenter = [buyerlon, buyerlat];
        var buyeroptions = { steps: 50, units: "kilometers" };
        var buyerpoint = turf.point(buyercenter);
        var buyercircle = turf.circle(buyercenter, buyerradius, buyeroptions);

        var searchWithin = turf.polygon([buyercircle.geometry.coordinates[0]]);
    }

    const shopMarker = [];
    const shopsWithinUserArray = [];
    const userWithinShopsArray = [];

    useEffect(() => {
        
        document.body.scrollTo(0, 0);

        const fetchShopsLoc = async () => {
            try {
                const getShopsLoc = await UserData.get('/mapdata');

                setShopsLoc(getShopsLoc.data.data.shops);

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchShopsLoc();

        const fetchPaymentCheck = async () => {
            try {
                await axiosAuth.get('/check', {
                    headers: {
                        user: userAuth.user
                    }
                }).then(data => {
                    setPayementSetup(data.data)
                });

            } catch (err) {
                setPayementSetup(false)
                console.error(err.message);
            }
        }

        fetchPaymentCheck();

    }, [setShopsLoc]);

    const stripeOnclick = async (e) => {
        e.preventDefault();

        setShowLoader(true)

        try {
            const getStripe = await axiosAuth.get('/stripe', {
                headers: {
                    user: userAuth.user
                }
            });
            window.location.href = getStripe.data;
        } catch (err) {
            console.error(err.message);
        }

    }

    useEffect(() => {

        if (search !== null) {
            if (shopsLoc.length > 0) {
                shopsLoc.map(shop => {
                    var shopradius = Number(shop.sellingdistance);
                    let shoplon = Number(shop.location.split(',')[0]), shoplat = Number(shop.location.split(',')[1])
                    var shopcenter = [shoplon, shoplat];
                    var shopoptions = { steps: 50, units: "meters", name: shop.username, id: shop.userid };
                    var shopcircle = turf.circle(shopcenter, shopradius, shopoptions);
                    var shopPoint = turf.point(shopcenter, shopoptions);
                    var shopPoly = turf.polygon([shopcircle.geometry.coordinates[0]]);

                    shopMarker.push([shopPoint, shopcircle, shopPoly]);
                })
            }

            shopMarker.map(shop => {
                var pointsWithinBuyer = turf.pointsWithinPolygon(shop[0], searchWithin);
                var pointWithinSeller = turf.pointsWithinPolygon(buyerpoint, shop[2]);

                if (pointsWithinBuyer.features[0] !== undefined) {
                    shopsWithinUserArray.push(pointsWithinBuyer.features[0].properties.id);
                }

                if (pointWithinSeller.features[0] !== undefined) {
                    userWithinShopsArray.push(shop[0].properties.id);
                }
            })

            const fetchResults = async () => {
                try {
                    await UserData.get('/shops', {
                        headers: {
                            sellers: JSON.stringify(userWithinShopsArray),
                            buyers: JSON.stringify(shopsWithinUserArray),
                            tags: JSON.stringify([]),
                        }
                    }).then(data => {
                        setShops(data.data)
                    });

                } catch (err) {
                    console.error(err.message);
                }
            }

            if (userWithinShopsArray !== undefined && userWithinShopsArray !== undefined && userWithinShopsArray.length > 0 && userWithinShopsArray.length > 0) {
                fetchResults();
            }
        }

    }, [shopsLoc]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchProductData = async () => {
            try {
                const getProduct = await UserData.get('/products', {
                    headers: {
                        user: username
                    }
                });

               isMounted && setProducts(getProduct.data.data.product);


                // isMounted && setProducts(...products, getProduct.data.data.product);

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchProductData();

        const fetchUserData = async () => {
            try {
                const getUser = await UserData.get('/' + username, {
                    signal: controller.signal
                });

                isMounted && setSelectedUser(getUser.data.data);

            } catch (err) {
                window.location.replace("/");
                console.error(err.message);
            }
        };

        fetchUserData();

        const fetchReviews = async () => {
            try {
                const getReview = await UserData.get('/getReview', {
                    headers: {
                        user: username
                    }
                });

               isMounted && setReview(getReview.data);

            } catch (err) {
                console.error(err.message);
            }
        };

        fetchReviews();

       return () => {
            isMounted = false;
            controller.abort();
        }
    }, [username]);

    const avgReviewArray = [];

    useEffect(() => {

        if (review.length > 0) {
            review.map(data => {
                avgReviewArray.push(Number(data.rating))
            })

            if (avgReviewArray.length > 0) {
                const average = array => array.reduce((a, b) => a + b) / array.length;
                const checkAvg = average(avgReviewArray).toFixed(1);

                if (Number(checkAvg) > 5) {
                    values.avgReview = 0;
                    // setAvgReview(Number(0))
                } else {
                    values.avgReview = Number(checkAvg);
                    // setAvgReview(Number(checkAvg))
                }
            }
    
            setAmountReviews(avgReviewArray.length);
        } else {
            setAmountReviews(0);
            // setAvgReview(0)
            values.avgReview = 0;
        }

    }, [review]);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value
        });
      };

    const verifyUser = () => {
        if (!userView) {
            return <></>;
        } else if (userView === username) {
            return <UserButtonsWrapper>
                <UserMyOrders to="/myorders"><motion.button className="UserOrderButton" whileTap={{ scale: 0.9 }}>My orders</motion.button></UserMyOrders>
                <Popup className="NewPostPopup" trigger={<UserNewPostButton><motion.button className="UserButton" whileTap={{ scale: 0.9 }}>Add product</motion.button></UserNewPostButton>}>
                    {close => (
                        <div>
                            <CloseProduct onClick={close} >
                                <GrClose size={25} color="#f0929f" />
                            </CloseProduct>
                            <NewPost close={close} username={username} user={selectedUser.user.currency} />
                        </div>
                    )}
                </Popup>
                <UserSettings to="/settings"><FiSettings size={25} color="black" /></UserSettings>
            </UserButtonsWrapper>;
        } else {
            return <></>;
        }
    }

    const serviceData = JSON.parse(localStorage.getItem(username));
    var service;
    var delivery;

    if (selectedUser !== null) {

        if (selectedUser.user.stripe === false && userAuth.username === undefined || selectedUser.user.stripe === false && userAuth.username !== username) {
            window.location.href = "/";
        }

        const curr = currencyArray[selectedUser.user.currency][0];

        if (serviceData !== null) {
            if (serviceData.delivery === true && serviceData.pickup === true) {
                service = " for delivery and free pick up";
            } else if (serviceData.delivery === true) {
                service = " for only delivery";
            } else if (serviceData.pickup === true) {
                service = "Only pick up";
            } else {
                service = "Not available in your area";
            }

            if (selectedUser.user.mindeliveryprice > 0 && selectedUser.user.delivery === true && serviceData.delivery === true) {
                delivery = "Order min. " + curr + " " + selectedUser.user.mindeliveryprice;
            } else {
                delivery = ""
            }
        } else if (search !== null) {
            service = "Not available in your area";
        } else {
            if (selectedUser.user.delivery === true && selectedUser.user.pickup === true) {
                service = " for delivery and free pick up";
            } else if (selectedUser.user.delivery === true) {
                service = " for only delivery";
            } else if (selectedUser.user.pickup === true) {
                service = "Only pick up";
            }

            if (selectedUser.user.mindeliveryprice > 0 && selectedUser.user.delivery === true) {
                delivery = "Order min. " + curr + " " + selectedUser.user.mindeliveryprice;
            } else {
                delivery = ""
            }
        }
    }

    // useEffect(() => {
    //         products.map(product => {
    //             if (serviceData !== null) {
    //                 if (product.delivery !== serviceData.delivery && product.pickup !== serviceData.pickup) {
    //                     setShownProducts(...shownProducts, product)
    //                 } else if (product.delivery !== serviceData.delivery) {
    //                     setShownProducts(...shownProducts, product)
    //                 } else if (product.pickup !== serviceData.pickup) {
    //                     setShownProducts(...shownProducts, product)
    //                 }
    //             } else {
    //                 setShownProducts(...shownProducts, product)
    //             }
    //         })
    // }, [products, setShownProducts]);

    const setProductCards = () => {
        if (products.length > 0) {
            return products.map(product => <ProductCards key={product.productid} product={product} available={available} user={selectedUser.user.currency} closed={selectedUser.user.open} />)
        } else {
            return <p>Add your products so they show up here!</p>
        }
    }

    const setReviewCards = () => {
        if (review.length > 0) {
            return review.map(data => <Review key={data.reviewid} review={data}/>)
        } else {
            return <p>No reviews here yet!</p>
        }
    }

    useEffect(() => {
        if (shops.length !== 0) {
            const check = [];
            shops.results.map(shop => { check.push(shop.username) })
            if (check.includes(username) === true) {
                setAvailable(true)
            } 
        }
    }, [shops])

    const setTime = () => {
        if (search === null) {
            return <Closed>Enter postcode to see availability</Closed>
        } else if (selectedUser.user.open === 0) {
            return <Closed>Closed</Closed>
        } else if (available === true) {
            return <MinOrder>{delivery} {service}</MinOrder>
        } else {
            return <Closed>Not available in your area</Closed>
        }
    }

    return (
        !paymentSetup && userView === username ? (
            <SetupStripeContainer>
                <StripeContent>
                    <StripeTitle>Almost done, payment information is needed!</StripeTitle>
                    {!showLoader ? (
                            <motion.button className="setupstripebutton" whileTap={{ scale: 0.9 }} onClick={stripeOnclick}>Click here to set up Stripe!</motion.button>
                        ) : (
                            <img src={spinner} alt="Loading" width="48" height="48" />
                        )
                    }
                </StripeContent>
            </SetupStripeContainer>
        ) : (
            <div>
                {selectedUser && (
                    <>
                        <UserContainer>
                            <UserWrapper>
                                <UserImageContainer>
                                    <UserPicWrapper>
                                        <UserImageWrapper>
                                            <UserImage src={selectedUser.user.profileimage} alt="Profile picture" />
                                        </UserImageWrapper>
                                    </UserPicWrapper>
                                </UserImageContainer>
                                <UserProfileWrapper>
                                    <NameReviewWrapper>
                                        <UserName>{selectedUser.user.businessname}</UserName>
                                        {/* {console.log(avgReview)} */}
                                        <Popup className="RatingPopup" trigger={<UserRatingsWrapper><Rating className="UserRating" name="read-only" value={values.avgReview} onChange={handleChange} size="small" precision={0.5} readOnly /> <UserRatings><u>{values.avgReview}/5</u> | {amountReviews}</UserRatings></UserRatingsWrapper>}>
                                            {close => (
                                                <div>
                                                    <CloseProduct onClick={close} >
                                                        <GrClose size={25} color="#f0929f" />
                                                    </CloseProduct>
                                                    <ReviewPopupContainer>
                                                        <ReviewTitle>Reviews</ReviewTitle>
                                                        {setReviewCards()}
                                                    </ReviewPopupContainer>
                                                </div>
                                            )}
                                        </Popup>
                                        <MinOrder>{setTime()}</MinOrder>
                                    </NameReviewWrapper>
                                    <UserBio>{selectedUser.user.bio}</UserBio>
                                    <UserPostcode title={selectedUser.user.place}><BiMap className="UserLocIcon" color="black" />{selectedUser.user.place}</UserPostcode>
                                    {verifyUser()}
                                    {!selectedUser.user.verified ? (
                                        <></>
                                    ) : (
                                        <UserVerified><FaCheckCircle className="Verified" color="#f0929f" /> verified</UserVerified>
                                    )
                                    }
                                </UserProfileWrapper>
                            </UserWrapper>
                            <UserCardWrapper>
                                <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 260,
                                        damping: 20
                                    }}
                                >
                                    {setProductCards()}
                                </motion.div>
                            </UserCardWrapper>
                        </UserContainer>
                    </>
                )}
            </div>
        )
    )
}

export default User
