import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';

export const HomeBackButton = styled(LinkRouter)`

`;

export const SignUpContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: table;
`;

export const TOS = styled(LinkRouter)`
    text-decoration: none;
    cursor: pointer;
    color: #bfbfbf;

    &:hover {
        color: #f0929f;
    }
`;

export const SignUpLogo = styled.img`
    margin-top: 20px;
    margin-bottom: 20px;
    width: 135px;
`;

export const InputContainer = styled.form`


    // @media (max-width:768px)  {
    //     margin-left: 60px;
    // }

    @media (max-width:480px)  {
        margin: auto;
        width: 278px;
    }

    // @media (max-width:375px)  {
    //     margin-left: 25px;
    // }
`;

export const InputSection = styled.div`
    width: 278px;
    float: left;
`;

export const InputWrapper = styled.div`
    text-align: left;
    padding: 5px;
`;

export const Label = styled.div`
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 8px;
`;

export const Input = styled.input`
    width: 268px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
`;

export const InputCheckbox = styled.input`
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 10px;
    outline: none;
`;

export const TOSlink = styled(LinkRouter)`

`;

export const TOSCheckbox = styled.div`
    margin-left: 4px;
    margin-top: 20px;
    display: flex;
    float: left;
`;

export const SubmitSection = styled.div`
    width: 100%;
    display: flex;
    margin-left: 5px;
    padding-bottom: 60px;
`;

export const LabelCheckbox = styled.div`
    margin-left: 4px;
    font-size: 14px;
    padding: 4px;
`;

export const LegalPopup = styled.div`
    font-size: 17px;
`;

export const LinkReadMore = styled(LinkRouter)`
  
`;

export const ConfirmCenter = styled.div`
    float: right;
`;