import React from "react";
import {
    Wait,
    OrderListWrapper,
    CheckOutListTitle,
    CheckOutLine,
    TotalCostWrapper,
} from './BuildingElements';

const Building = () => {
    return (
        <Wait>
            <OrderListWrapper>
                <CheckOutListTitle>Thank you!</CheckOutListTitle>
                <CheckOutLine />
                <TotalCostWrapper>
                    <p>We need time to setup your shop:</p>
                    <ul>
                        <li>Within 3 days you'll receive an email to complete your account</li>
                    </ul>
                </TotalCostWrapper>
            </OrderListWrapper>
        </Wait>
    )
}

export default Building