import styled from 'styled-components';

export const ReviewContainer = styled.div`
    width: 420px;
    background: #f5f5f5;
    margin: 15px;
    border-radius: 10px;
    padding: 10px;

    @media (max-width:480px)  {
        width: 100%;
        margin: auto;
        margin-bottom: 10px;
        margin-top: 10px;
    }
`;

export const ReviewInfoWrapper = styled.div`
    width: 200px;
    height: fit-content;
    position: absolute;
`;

export const UserName = styled.div`
    font-size: 14px;
`;

export const ProductName = styled.div`
    font-size: 11px;
`;

export const ReviewData = styled.div`
    font-size: 11px;
    width: 140px;
    float: right;
`;

export const ReviewDate = styled.div`
    font-size: 12px;
`;

export const ReviewStars = styled.div`
    font-size: 11px;
`;

export const ReviewText = styled.div`
    font-size: 13px;
    display: inline-block;
    height: 40px;
    width: 100%;
    margin-top: 5px;
    line-break: anywhere;
`;

export const UserRating = styled.div`
    margin-top: 10px;
    margin-left: 5px;
    font-size: 12px;
    width: fit-content;
    float: right;
`;

